/* eslint-disable radix */
import React from 'react';
import styled from '@emotion/styled';
import { AppVariants, useAppVariant } from 'utils/hooks/useAppVariant';
import { colors } from './helpers/plushcare-style-defaults';

const Circle = (props) => {
  const { variant } = useAppVariant();
  if (variant === AppVariants.AccoladeCare) {
    return null;
  }
  const CircleContainer = styled.div`
    z-index: ${props.content ? '0' : '-1000'};
    width: 100%;
    position: ${props.content ? 'relative' : 'fixed'};
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      border-bottom-left-radius: 100vw;
      border-bottom-right-radius: 100vw;
      width: 200vw;
      background: ${props.color ? props.color : colors.cloud};
      content: "";
      @media (min-width: 1100px){
        height: ${props.contentHeight ? `${parseInt(props.contentHeight) * 1.62}px` : '100vw'};
      }
      @media (max-width: 1100px) and (min-width: 768px) {
        height: ${props.contentHeight ? `${parseInt(props.contentHeight) * 1.63}px` : '150vw'};
      }
      @media (max-width: 768px) and (min-width: 500px) {
        height: ${props.contentHeight ? `${parseInt(props.contentHeight) * 1.68}px` : '200vw'};
      }
      @media (max-width: 500px){
        height: ${props.contentHeight ? `${parseInt(props.contentHeight) * 1.75}px` : '340vw'};
      }
      position: absolute;
      z-index: -1;
    }
  `;

  return (
    <CircleContainer {...props} />
  );
};

export default Circle;
