import React, {
  createContext,
  FC,
} from 'react';

import { AppVariants, Variant } from 'utils/hooks/useAppVariant';
import { DEFAULT_EMAIL } from 'src/js/nextgen/components/Email/Email';

export type Brand = {
  display_name: string;
  application_title: string;
  support_email: string;
  support_phone: string;
  color_scheme: Variant;
  application_default: Variant;
  is_default: boolean;
  is_accolade_advocacy_app: boolean;
  app_download_url: string;
}

export type BrandProviderProps = {
  brand: Brand;
}

export const DEFAULT_PLUSHCARE_BRAND = {
  application_title: 'PlushCare',
  color_scheme: AppVariants.PlushCare,
  display_name: 'PlushCare',
  name: AppVariants.PlushCare,
  support_email: DEFAULT_EMAIL,
  support_phone: '(800) 221-5140',
};

export const BrandContext = createContext<Brand | undefined>(undefined);

export const BrandProvider: FC<React.PropsWithChildren<BrandProviderProps>> = ({ brand, children }) => {
  return (
    <BrandContext.Provider value={brand}>
      {children}
    </BrandContext.Provider>
  );
};

export const useBrand = () => {
  const context = React.useContext(BrandContext);
  if (context === undefined) {
    throw new Error('useBrand must be used within a BrandProvider');
  }
  return context;
};
