import React, { ReactNode, useCallback, useState } from 'react';
import { useUserData } from 'src/js/endpoints/users';
import { apiUrl, PlushcareWebAPI } from 'src/js/utils';
import { useFeatureFlag } from 'src/js/abTesting/hooks';
import TosConsentModal from 'components/myHealth/TermsOfServiceModal';

type TermsOfServiceProviderProps = {
  children: ReactNode;
};

type ConsentFormData = {
  form_uuid: string,
  title: string,
  version: number,
  name: string,
  opted_in?: boolean,
  content_last_updated?: string,
  effective_on?: string,
}

const TermsOfServiceProvider = ({ children }: TermsOfServiceProviderProps) => {
  const [consentedToTos, setConsentedToTos] = useState<boolean>(true);
  const [consentFormData, setConsentFormData] = useState<ConsentFormData | null>(null);
  const [{ enabled: tosOptInStatusCheckEnabled }] = useFeatureFlag('enable_tos_opt_in_status_check', { autoUpdate: true });
  const { data: userData } = useUserData();
  const userUuid = userData.user_id;

  React.useEffect(
    () => {
      const fetchFormContent = async () => {
        const response = await PlushcareWebAPI.apiGet(apiUrl.treecko.consentForms, {
          user_uuid: userUuid,
          consent_form: 'ToS',
        });
        if (response.status !== 200) {
          return;
        }
        const { data: { payload } } = response;
        setConsentFormData(payload[0]);
        setConsentedToTos(payload[0]?.opted_in);
      };

      if (tosOptInStatusCheckEnabled && userUuid) {
        fetchFormContent();
      }
    },
    [
      tosOptInStatusCheckEnabled,
      userUuid,
    ],
  );

  const updateConsentFormStatus = useCallback(
    async () => {
      const formUuid = consentFormData?.form_uuid;
      const payload = {
        consenter_uuid: userUuid,
        opt_in: true,
      };
      if (formUuid) {
        const { data } = await PlushcareWebAPI.apiPatch(
          apiUrl.treecko.consentFormStatusUpdate(formUuid),
          payload,
          true,
        );
        setConsentFormData({ ...consentFormData, ...data.payload });
        setConsentedToTos(data.payload.opted_in);
      }
    },
    [
      userUuid,
      consentFormData,
    ],
  );

  return (
    <div data-testid="tos-provider">
      {children}
      <TosConsentModal isOpen={!consentedToTos} onSubmit={updateConsentFormStatus} />
    </div>
  );
};

export default TermsOfServiceProvider;
