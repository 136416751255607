import { isServer } from '../../utils/server';

export const isIOS = !isServer && (window.navigator.platform === 'iPhone' || window.navigator.platform === 'MacIntel');
export const appLink = 'https://plushcare.onelink.me/DRmO/21ab4e70'; // short link to visit page
export const appLink_ios = 'https://apps.apple.com/us/app/plushcare-video-doctor-visits/id955183607';
export const appAccoladeCareLink_ios = 'https://apps.apple.com/us/app/1640306909';
export const appAccoladeLink_ios = 'https://apps.apple.com/us/app/accolade-inc/id1203452254';
export const appLink_android = 'https://play.google.com/store/apps/details?id=com.plushcare.plushcare.prod';
export const appAccoladeCareLink_android = 'https://play.google.com/store/apps/details?id=com.plushcare.accoladecare.prod';
export const appAccoladeLink_android = 'https://play.google.com/store/apps/details?id=com.accolade.mobile.prod&hl=en_US&gl=US&pli=1';
// TODO: Update these links when we have them.
export const appLink_acc = 'https://accoladecare.onelink.me/L5gS/download'; // short link to visit page
export const appLink_ios_acc = 'https://apps.apple.com/us/app/accoladecare-video-doctor-visits/id1640306909';
export const appLink_android_acc = 'https://play.google.com/store/apps/details?id=com.accoladecare.accoladecare.prod';
export const benefits = [
  'Online same-day appointment booking',
  'In-app messaging',
  'Online care team',
];
export const becomeMemberText = 'Become a member with a 30-day free trial.';
