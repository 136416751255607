import Cookies from 'js-cookie';
// FIXME: Circular dependency! Needs to be resolved
// import { Analytics } from './analytics';
/**
 *
 * TODO: Deprecate using this, this abstraction has no gain in comparison to direct using js-cookies
 * js-cookies does not raise any exceptions and all try catches here are dead code
 */
class CookieStorage {
  static storage = null;

  /**
    * Sets a key to a value, expireation is provided as optional
    * @param {*} key storage key
    * @param {*} value storage value tied to key
    * @param {*} attributes dictionary of values on expiration, path
    * */
  static set(key, value, attributes = null) {
    try {
      this.storage.set(key, value, attributes);
    } catch (e) {
      // FIXME: Commented out because it causes a circular dependency
      // Analytics.otherException(e);
    }
  }

  static get(key) {
    try {
      return this.storage.get(key);
    } catch (e) {
      return undefined;
    }
  }

  static remove(key, attributes) {
    try {
      this.storage.remove(key, attributes);
    } catch (e) {
      // FIXME: Commented out because it causes a circular dependency
      // Analytics.otherException(e);
    }
  }

  /**
    * Sets a key to a value, expireation is provided as optional
    * @param {*} cookieObject storage key
    * @param {*} attributes dictionary of values on expiration, path
    * */
  static setItemsFromObject(cookieObject, attributes) {
    Object.keys(cookieObject).forEach((cookieName) => {
      this.set(cookieName, cookieObject[cookieName], attributes);
    });
  }

  /**
    * Checks to see if a cookie is enabled
    */
  static areCookiesEnabled = () => {
    try {
      document.cookie = 'testcookie';
      const cookieEnabled = document.cookie.indexOf('testcookie') !== -1;
      if (cookieEnabled) {
        document.cookie = `testcookie; expires=${new Date(0).toUTCString()}`;
      }
      return cookieEnabled;
    } catch (e) {
      return false;
    }
  };

  static init() {
    try {
      this.storage = (this.areCookiesEnabled()) ? Cookies : null;
    } catch (e) {
      // FIXME: Commented out because it causes a circular dependency
      // Analytics.otherException(e);
      this.storage = null;
    }
  }
}

CookieStorage.init();
export default CookieStorage;
