import {
  OptimizelyContext, OptimizelyDecideOption, useDecision, setLogLevel, OptimizelyDecision as OptimizelyDecisionType,
} from '@optimizely/react-sdk';
// eslint-disable-next-line import/no-unresolved
import { OptimizelyContextInterface } from '@optimizely/react-sdk/dist/Context';
import {
  useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import * as Sentry from '@sentry/nextjs';
import { AppVariants, useAppVariant } from 'utils/hooks/useAppVariant';
import * as env from 'utils/env';
import { isBrowser } from 'src/js/utils/server';
import DevLogger from 'src/js/logging/DevLogger';

// see this Confluence space for more information on our AB testing framework:
// https://accoladeinc.atlassian.net/wiki/spaces/ENGINEERIN/pages/1459224598/Optimizely
export const useABTestContext = () => useContext<OptimizelyContextInterface>(OptimizelyContext);

/**
 * Returns Sentry event ID string on success, null on failure/if Optimizely context values are undefined
 */
export const useLogABTestError = (err: any): { eventId: string | null, loading: boolean } => {
  const { variant } = useAppVariant();
  const ctx = useABTestContext();
  const abTestErrSent = useRef(false);
  const [loading, setLoading] = useState(true);
  const [eventId, setEventId] = useState<string | null>(null);

  const logTestErr = useCallback(async () => {
    if (!ctx || !ctx?.optimizely || abTestErrSent.current) return;
    const { optimizely } = ctx;
    await optimizely.onReady();
    const decisionData = optimizely.decideAll([
      OptimizelyDecideOption.DISABLE_DECISION_EVENT,
    ]);
    const errorLabel = Object
      .keys(decisionData)
      .map(key => `${key}:${decisionData[key].variationKey}`)
      .sort()
      .join(';');
    const sentryEventId = Sentry.captureException(err, {
      tags: {
        abTest: errorLabel,
      },
    });
    abTestErrSent.current = true;
    setEventId(sentryEventId);
    setLoading(false);
  }, [err, ctx]);

  useEffect(() => {
    // AB testing is not enabled on AccoladeCare
    if (variant === AppVariants.PlushCare && !abTestErrSent.current) {
      logTestErr();
    } else {
      setLoading(false);
    }
  }, [abTestErrSent, ctx, err, logTestErr, variant]);
  return { eventId, loading };
};

// If not on Prod log Optimizely debug logs to console
if (!env.is('prod')) {
  setLogLevel('debug');
}

// we wait 5 seconds for the client to become ready, and if it doesn't, we return false
export type useIsFeatureEnabledResponse = {
  decisionEnabled: boolean | undefined;
  isReady: boolean;
  didTimeout: boolean;
  optimizelyDecision: OptimizelyDecisionType;
}

declare global {
  interface Window {
    DD_RUM: any;
  }
}

// more complex hook with additional handling of timeouts and client readiness
// only evaluated on the client
export const useIsFeatureEnabled = (...args: Parameters<typeof useDecision>): useIsFeatureEnabledResponse => {
  DevLogger.log('Optimizely useIsFeatureEnabled called');
  const [flagKey, options = { autoUpdate: true, timeout: 3000 }, overrides] = args;
  const [OptimizelyDecision, ClientReady, DidTimeout] = useDecision(flagKey, options, overrides);
  const [decision, setDecision] = useState<boolean | undefined>(undefined);
  const [didTimeout, setDidTimeout] = useState<boolean>(DidTimeout);

  // create a timeout function. when 3 seconds are reached we will set the decision to false
  // this edge case is different than the client not being ready, which is handled below
  // when Optimizely's SDK has a problem with initialization it will never become ready nor will it timeout.

  useEffect(() => {
    const clearTimerRef = setTimeout(() => {
      DevLogger.log(`Optimizely client timed out after 3 seconds. Feature flag ${flagKey} will not be enabled.`);
      DevLogger.log('OptimizelyDecision', OptimizelyDecision);
      DevLogger.log('decision', decision);
      setDidTimeout(true);
      setDecision(false);
    }, 3000);
    const updateDecision = () => {
      if (DidTimeout && decision === undefined) {
        DevLogger.log(`Optimizely client timed out after 5 seconds. Feature flag ${flagKey} will not be enabled.`);
        setDecision(false);
        return;
      }

      if (ClientReady) {
        if (OptimizelyDecision === undefined) {
          DevLogger.log(`Optimizely client is ready, but decision for feature flag ${flagKey} is undefined.`);
          setDecision(false);
          return;
        }

        DevLogger.log(`Optimizely client is ready. Feature flag ${flagKey} will be ${OptimizelyDecision.enabled}`);
        // due to this being in a useEffect we don't really need to check if we're in the browser as the useEffect
        // will only run on the client, but for safety + symmetry with useFeatureFlag we check anyway
        if (isBrowser() && window.DD_RUM) {
          window.DD_RUM.addFeatureFlagEvaluation(flagKey, `${flagKey}:${OptimizelyDecision.enabled}:${OptimizelyDecision.variationKey}`);
        }
        setDecision(OptimizelyDecision.enabled);
      }
    };
    updateDecision();
    if (decision !== undefined) {
      clearTimeout(clearTimerRef);
    }
    return () => {
      clearTimeout(clearTimerRef);
    };
    // Dependencies for the useEffect
  }, [OptimizelyDecision, ClientReady, DidTimeout, flagKey, decision]);
  return {
    decisionEnabled: decision, isReady: ClientReady, didTimeout, optimizelyDecision: OptimizelyDecision,
  };
};

// simple wrapper around useDecision that logs the feature flag evaluation to Datadog
// can be evaluated on the client or server
export const useFeatureFlag = (...args: Parameters<typeof useDecision>): ReturnType<typeof useDecision> => {
  const [flagKey, options, overrides] = args;
  const decision = useDecision(flagKey, options, overrides);
  // this hook can get called on the server, so we need to explicitly check if we're in the browser as otherwise
  // window will be undefined, and we'll get a reference error (optional chaining doesn't work here)
  if (isBrowser() && window.DD_RUM) {
    window.DD_RUM.addFeatureFlagEvaluation(flagKey, `${flagKey}:${decision[0].enabled}:${decision[0].variationKey}`);
  }
  return decision;
};
