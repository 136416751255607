import styled from '@emotion/styled';
import React from 'react';
import { useBrand } from 'src/js/nextgen/providers/BrandProvider';

const DEFAULT_LOCAL_PART = 'info';
export const DEFAULT_EMAIL = `${DEFAULT_LOCAL_PART}@plushcare.com`;

const StyledEmail = styled.a``;

type EmailProps = {
  localPart?: string;
}

export const Email = ({ localPart = DEFAULT_LOCAL_PART }: EmailProps) => {
  const { support_email = DEFAULT_EMAIL } = useBrand();
  const customEmail = support_email.replace(DEFAULT_LOCAL_PART, localPart);
  return (
    <StyledEmail className="support-email" href={`mailto:${customEmail}`}>
      {support_email}
    </StyledEmail>
  );
};
