/* eslint-disable react/require-default-props */
import React, { ReactNode, SyntheticEvent } from 'react';
import styled from '@emotion/styled';

type Props = {
  children: ReactNode
  id?: string
  type?: 'button' | 'submit'
  onClick?: (e: SyntheticEvent) => void
  onKeyPress?: () => void
  // to enable support of `styled` component
  className?: string
  dataTestId?: string
}

export function ButtonAsLink({
  children, className, onClick, onKeyPress, type = 'button', id, dataTestId,
}: Props) {
  return (
    <Wrapper
      className={className}
      onClick={onClick}
      onKeyPress={onKeyPress}
      type={type}
      id={id}
      data-testid={dataTestId}
    >
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }

  &:disabled:hover {
    cursor: default;
  }
`;
