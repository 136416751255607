import {
  appLink,
  appLink_acc,
  appLink_android,
  appLink_android_acc,
  appLink_ios,
  appLink_ios_acc,
} from 'src/js/nextgen/utils/text';

export const APP_VARIANT = process.env.NEXT_PUBLIC_APP_VARIANT;
export const IS_ACCOLADE_X = Boolean(process.env.NEXT_PUBLIC_APP_ZONE) && process.env.NEXT_PUBLIC_APP_ZONE !== '/';

export type Variant = 'plushcare' | 'accoladecare' | 'bsc' | 'celeste';
export type AppVariantType = {
  PlushCare: Variant,
  AccoladeCare: Variant,
  Bsc: Variant,
  Celeste: Variant,
}
export const AppVariants: AppVariantType = {
  PlushCare: 'plushcare',
  AccoladeCare: 'accoladecare',
  Bsc: 'bsc',
  Celeste: 'celeste',
};

export interface AppVariant {
  appLink: string;
  appLink_android: string;
  appLink_ios: string;
  displayName: 'PlushCare' | 'Accolade Care';
  variant: Variant;
  isAccoladeCare: boolean;
  isAccoladeX: boolean;
}

export const useAppVariant = (__TEST_VARIANT__?: Variant): AppVariant => {
  const variant: Variant = (__TEST_VARIANT__ || APP_VARIANT || AppVariants.PlushCare) as Variant;

  return {
    appLink: variant === AppVariants.AccoladeCare ? appLink_acc : appLink,
    appLink_android:
      variant === AppVariants.AccoladeCare
        ? appLink_android_acc
        : appLink_android,
    appLink_ios:
      variant === AppVariants.AccoladeCare
        ? appLink_ios_acc
        : appLink_ios,
    displayName:
      variant === AppVariants.AccoladeCare ? 'Accolade Care' : 'PlushCare',
    variant,
    isAccoladeCare: variant === AppVariants.AccoladeCare,
    isAccoladeX: IS_ACCOLADE_X,
  };
};

// util function mainly for unit testing w/ jest.spyOn(...)
type IsAccoladeX = boolean;
export const getAppVariant = (): [Variant, IsAccoladeX] => {
  return [(APP_VARIANT || AppVariants.PlushCare) as Variant, IS_ACCOLADE_X] as const;
};

export const useDefaultSupportNumber = () => {
  const { variant } = useAppVariant();
  return variant === AppVariants.AccoladeCare
    ? '(866) 518-1774'
    : '(800) 221-5140';
};
