import { HYDRATE } from 'next-redux-wrapper';

import {
  CLEAR_JOIN_APPOINTMENT, CLEAR_SOCIAL_ERROR, COMPLETE_ITEM_FROM_TASKS_MODAL, GET_DASHBOARD_DATA_SUCCESS,
  GET_PRESCRIPTIONS_DATA_SUCCESS, GET_TASKS_DATA_SUCCESS, GET_TESTS_AND_RESULTS_DATA_SUCCESS,
  GET_VISIT_HISTORY_DATA_SUCCESS, GET_YOUR_HEALTH_DATA_SUCCESS, JOIN_APPOINTMENT,
} from '../actionTypes/ChameleonActionType';

import { parseFollowupAppointment } from './helpers';
import { Prescription } from '../components/dashboard/Prescriptions/PrescriptionListItem';
import { isDateBefore } from '../utils';
import { BaseAppointment, Doctor } from '../components/dashboard/DashboardTypes';
import { UnavailableAppointment, UpcomingAppointment } from '../globalTypes';
import { UserSubscription } from './UserReducer';

export const initalState: StoreState = {
  dashboard: {
    async_questionnaire: { display: false },
    actionable_tasks: null,
    appointments_history: 0,
    display_ph: false,
    orders: 0,
    prescriptions: 0,
    unavailable_appointments: [],
    upcoming_appointments: [],
    total_completed_item: 0,
    total_items: 0,
    display_async_questionnaire: false,
    rx_encounters: [],
    subscription_memberships: [],
    unread_notifications: 0,
  },
  tasks: {
    completed: [],
    pending: [],
    recommended: [],
  },
  prescriptions: null,
  visitHistory: null,
  testsAndResults: null,
  yourHealth: null,
  joinedAppointment: null,
};

function DashboardReducer(state = initalState, action: Actions) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.dashboard,
        // append possible changes which can happen only in the FE side
        joinedAppointment: state.joinedAppointment,
      };

    case GET_DASHBOARD_DATA_SUCCESS:
      if (action.dashboard.actionable_tasks) {
        const { actionable_tasks, upcoming_appointments } = action.dashboard;
        const followupAppointments = parseFollowupAppointment(actionable_tasks.tasks);
        // eslint-disable-next-line no-param-reassign
        action.dashboard.upcoming_appointments = [...upcoming_appointments, ...followupAppointments].sort(
          (a, b) => (isDateBefore(a.appointment_utc_time, b.appointment_utc_time) ? -1 : 1),
        );
      }

      return {
        ...state,
        dashboard: action.dashboard,
      };
    case GET_TASKS_DATA_SUCCESS:
      return {
        ...state,
        tasks: action.tasks,
      };
    case GET_PRESCRIPTIONS_DATA_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const sortedDates = Object.keys(action.prescriptions).sort((a, b) => (isDateBefore(a, b) ? -1 : 1));

      return {
        ...state,
        prescriptions: { ...action.prescriptions, sortedDates },
      };
    case GET_VISIT_HISTORY_DATA_SUCCESS:
      return {
        ...state,
        visitHistory: action.visitHistory,
      };
    case GET_TESTS_AND_RESULTS_DATA_SUCCESS:
      return {
        ...state,
        testsAndResults: action.testsAndResults,
      };
    case GET_YOUR_HEALTH_DATA_SUCCESS:
      return {
        ...state,
        yourHealth: action.yourHealth,
      };
    case COMPLETE_ITEM_FROM_TASKS_MODAL:
      return {
        ...state,
        tasks: {
          recommended: state.tasks.recommended.map((checklistItem) => {
            if (checklistItem.id === action.data.checklist_id) {
              // eslint-disable-next-line no-param-reassign
              checklistItem.completed_item = action.data.completed_item;
            }
            return checklistItem;
          }),
          completed: state.tasks.completed,
          pending: state.tasks.pending,
        },
      };
    case JOIN_APPOINTMENT:
      return {
        ...state,
        joinedAppointment: {
          appointmentID: action.appointmentID,
          appointmentTime: action.appointmentTime,
          appointmentTimezone: action.appointmentTimezone,
          sessionID: action.sessionID,
          doctor: action.doctor,
        },
      };
    case CLEAR_JOIN_APPOINTMENT:
      return {
        ...state,
        joinedAppointment: null,
      };
    case CLEAR_SOCIAL_ERROR:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          social_error: false,
        },
      };

    default:
      return state;
  }
}

export default DashboardReducer;

export interface StoreState {
  dashboard: {
    actionable_tasks?: { tasks: Array<ActionableTask> } | null
    upcoming_appointments: Array<UpcomingAppointment>,
    async_questionnaire: {
      display: boolean
    },
    display_ph?: boolean,
    appointments_history: number,
    prescriptions: number
    orders: number
    unavailable_appointments: Array<UnavailableAppointment>
    total_completed_item: number
    total_items: number
    display_async_questionnaire: boolean
    subscription_memberships: Array<UserSubscription>
    rx_encounters: unknown[]
    unread_notifications: number
  },
  tasks: {
    recommended: Array<{ id: number, completed_item: unknown }>
    completed: Array<{ id: number }>
    pending: Array<{ id: number }>
  },
  prescriptions: {[k: string]: Array<Prescription>} | null,
  visitHistory: null,
  testsAndResults: null,
  yourHealth: null,
  joinedAppointment: {} | null,
}

export type Actions = {
    type: typeof HYDRATE
    payload: { dashboard: StoreState }
  }
  | {
  type: typeof GET_DASHBOARD_DATA_SUCCESS
  dashboard: NonNullable<StoreState['dashboard']>
}
  | {
  type: typeof GET_TASKS_DATA_SUCCESS
  tasks: unknown
}
  | {
  type: typeof GET_PRESCRIPTIONS_DATA_SUCCESS
  prescriptions: {
    [p: string]: string | string[]
    sortedDates: string[]
  }
}
  | {
  type: typeof GET_VISIT_HISTORY_DATA_SUCCESS
  visitHistory: unknown
}
  | {
  type: typeof GET_TESTS_AND_RESULTS_DATA_SUCCESS
  testsAndResults: unknown
}
  | {
  type: typeof GET_YOUR_HEALTH_DATA_SUCCESS
  yourHealth: unknown
}
  | {
  type: typeof COMPLETE_ITEM_FROM_TASKS_MODAL
  data: {
    checklist_id: number
    completed_item: unknown
  }
  tasks: Tasks | null
}
  | {
  type: typeof JOIN_APPOINTMENT
  appointmentID: number
  appointmentTime: string
  appointmentTimezone: string
  sessionID: string
  doctor: unknown
}
  | {
  type: typeof CLEAR_JOIN_APPOINTMENT
}
  | {
  type: typeof CLEAR_SOCIAL_ERROR
}

export const ActionableTaskNamespace = {
  NextAppointment: 'NextAppointment',
  NextAppointmentPreventativeAWV: 'NextAppointment::Preventative::AWV',
};

export type ActionableTask = {
  namespace: string,
  completed: string | null,
  due: string,
  payload: {
    date: string,
    doctor: Doctor,
    recommended_appointment: BaseAppointment,
    previous_appointment: BaseAppointment,
    doctor_active: boolean,
    card_message: string,
    state: {
      name: string,
      abbreviation: string,
    },
  },
  id: number
}

export type Tasks = {
  recommended: Array<{ id: number, completed_item: unknown }>
  completed: Array<{ id: number }>
  pending: Array<{ id: number }>
}
