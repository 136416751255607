import { SELECT_LOCATION } from '../actionTypes/LocationActionTypes';
import { SET_LOCATION_COORDINATES } from '../actionTypes/BookingActionType';
import { UserAddress } from './UserReducer';
import { PharmacyAddress } from './PharmacyReducer';

export type LocationState = {
  selectedLocation: any,
  currentLatitude: number | null,
  currentLongitude: number | null,
  currentAddress: PharmacyAddress | null,
}

const initialState: LocationState = {
  selectedLocation: null,
  currentLatitude: null,
  currentLongitude: null,
  currentAddress: null,
};

function LocationReducer(state = initialState, action: Action) {
  switch (action.type) {
    case SET_LOCATION_COORDINATES:
      return {
        ...state,
        currentAddress: action.address,
        currentLatitude: action.latitude,
        currentLongitude: action.longitude,
      };
    case SELECT_LOCATION:
      return {
        ...state,
        selectedLocation: action.selectedLocation,
      };
    default:
      return state;
  }
}

export default LocationReducer;

type Action = {
  type: typeof SET_LOCATION_COORDINATES
  address: UserAddress
  latitude: number
  longitude: number
} | {
  type: typeof SELECT_LOCATION
  selectedLocation: any
}

export type Location = {
  city: string
  latitude: number
  longitude: number
}
