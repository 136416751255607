import React, { AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react';
import {
  Button as AdsButton,
  ButtonProps as AdsButtonProps,
  ButtonType as AdsButtonType,
  LinkButton as AdsLinkButton,
  LinkButtonProps as AdsLinkButtonProps,
} from '@accolade/design-system-react';
import { AppVariants, useAppVariant, Variant } from 'utils/hooks/useAppVariant';
import PlushButton from 'src/js/nextgen/plushcare-components/Button/Button';
import useBaseUrl from 'utils/hooks/useBaseUrl';

export const DEFAULT_SIZE = 'medium';
export const DEFAULT_BUTTON_TYPE = 'rounded';
export const DEFAULT_APPEARANCE = 'primary';

type ButtonSize = 'small' | 'medium';

// array of strings but in readonly mode
const plushButtonTypes = [
  'primary',
  'primary-tertiary',
  'secondary',
  'secondary-tertiary',
  'secondary-inverse',
  'navigation',
  'navigation-small-signed-out',
  'navigation-small-signed-in',
  'filter',
] as const;

// convert namesArr into string literal union type
export type PlushButtonTypes = typeof plushButtonTypes[number];

const buttonTypeMap: Partial<Record<PlushButtonTypes, AdsButtonType>> = {
  primary: 'filled',
  secondary: 'filled',
  'navigation-small-signed-in': 'filled',
};

const buttonSizeMap: Partial<Record<PlushButtonTypes, ButtonSize>> = {
  navigation: 'small',
  'navigation-small-signed-out': 'small',
  'navigation-small-signed-in': 'small',
  filter: 'small',
};

// This should extend ButtonHTMLAttributes<HTMLButtonElement>
export interface CommonButtonProps {
  buttonSize?: ButtonSize;
  /**
   * Used for setting the size only for the ADS button.
   */
  size?: ButtonSize;
  link?: string;
  tag?: 'a' | 'button';
  text?: string | null;
  type?: PlushButtonTypes;

  /**
   * ADS only props
   */
  buttonType?: AdsButtonType;

  // States
  isDisabled?: boolean;
  isLoading?: boolean;
  noLoader?: boolean;

  // Styles
  maxWidth?: string;
  minWidth?: string;
  width?: string;

  // Ignored by the ADS button and button link
  fontSize?: string;
  lineHeight?: string;
  height?: string;
  maxHeight?: string;
  minHeight?: string;
}

export type GenericButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & CommonButtonProps;
export type GenericLinkButtonProps = AnchorHTMLAttributes<HTMLAnchorElement> & CommonButtonProps;
export type PlushButtonProps = (GenericButtonProps | GenericLinkButtonProps) & {
  __TEST_VARIANT__?: Variant;
};

// maps old PlushCare button props to ADS button props
export const mapButtonProps = ({
  buttonSize = DEFAULT_SIZE,
  buttonType = DEFAULT_BUTTON_TYPE,
  isDisabled,
  isLoading,
  noLoader,
  size,
  type,

  // props to omit from ADS button
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  fontSize,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  height,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  lineHeight,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  maxHeight,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  minHeight,
  ...rest
}: GenericButtonProps): AdsButtonProps => ({
  appearance: DEFAULT_APPEARANCE,
  buttonType: (type && buttonTypeMap[type]) ? buttonTypeMap[type] : buttonType,
  disabled: isDisabled,
  isLoading: noLoader ? false : isLoading,
  size: (type && buttonSizeMap[type]) ? buttonSizeMap[type] : size || buttonSize,
  type: type === 'button' || type === 'reset' ? type : 'submit',
  ...rest,
});

// maps old PlushCare button props to ADS link button props
export const mapLinkButtonProps = ({
  buttonSize = DEFAULT_SIZE,
  size,
  type,

  // props to omit from ADS link button
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isDisabled,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isLoading,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  noLoader,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  fontSize,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  height,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  lineHeight,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  maxHeight,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  minHeight,
  ...rest
}: GenericLinkButtonProps): Omit<AdsLinkButtonProps, 'to'> => ({
  appearance: DEFAULT_APPEARANCE,
  buttonSize: (type && buttonSizeMap[type]) ? buttonSizeMap[type] : size || buttonSize,
  ...rest,
});

export const Button = ({
  __TEST_VARIANT__,
  buttonSize,
  children,
  link,
  tag,
  text,
  ...rest
}: PlushButtonProps) => {
  const { variant } = useAppVariant(__TEST_VARIANT__);
  const baseUrl = useBaseUrl(link);
  if (variant === AppVariants.AccoladeCare) {
    if (tag === 'a' && baseUrl) {
      return (
        <AdsLinkButton
          to={baseUrl}
          {...mapLinkButtonProps({ buttonSize, ...rest } as GenericLinkButtonProps)}
        >
          { children || text }
        </AdsLinkButton>
      );
    }
    return (
      <AdsButton
        {...mapButtonProps({ buttonSize, ...rest } as GenericButtonProps)}
      >
        { children || text }
      </AdsButton>
    );
  }
  return (
    <PlushButton buttonSize={buttonSize} link={baseUrl} tag={tag} text={text} {...rest}>
      {children}
    </PlushButton>
  );
};

export default Button;
