export const SET_FIRSTNAME = 'SET_FIRSTNAME';
export const SET_LASTNAME = 'SET_LASTNAME';
export const SET_DATE = 'SET_DATE';

export const SET_INSURANCE_PAYER = 'SET_INSURANCE_PAYER';
export const SET_INSURANCE_PAYER_ID = 'SET_INSURANCE_PAYER_ID';
export const SET_INSURANCE_MEMBER_ID = 'SET_INSURANCE_MEMBER_ID';

export const SET_INSURANCE_ERROR = 'SET_INSURANCE_ERROR';
export const SET_INSURANCE_SUCCESS = 'SET_INSURANCE_SUCCESS';

export const SET_INSURANCE_PRICE = 'SET_INSURANCE_PRICE';
export const SET_CLEAR_INSURANCE = 'SET_CLEAR_INSURANCE';
export const SET_CLEAR_INSURANCE_STORE = 'SET_CLEAR_INSURANCE_STORE';

export const SET_LOADING = 'SET_LOADING';

export const SET_VALID_INSURANCE_OBJECT = 'SET_VALID_INSURANCE_OBJECT';
export const SET_SELECT_NO_INSURANCE_OPTION = 'SET_SELECT_NO_INSURANCE_OPTION';

export const SET_COVERAGE_ID = 'SET_COVERAGE_ID';
export const SET_COVERAGE_TYPE = 'SET_COVERAGE_TYPE';

export const SET_LOCK_CREDENTIALS = 'SET_LOCK_CREDENTIALS';
export const SET_IN_NETWORK = 'SET_IN_NETWORK';
export const SET_EXPLANATION = 'SET_EXPLANATION';
export const CLEAR_PAYER = 'CLEAR_PAYER';
