import { PriceMethod } from 'src/js/endpoints/appointments';
import { PAYMENT_METHOD } from 'src/js/nextgen/utils/constants';
import { RootStore } from 'src/js/store/GlobalStore';

export type PaymentMethod = PriceMethod | 'no_choice';
type State = {
    method: PaymentMethod,
    // below is existing before state, we need to revisit it sense
    offerUUIDSelected: string | null,
    childSelected: string | null
}

export const actions = {
  setPaymentMethod: (method: PaymentMethod) => {
    return {
      type: 'SET_PAYMENT_METHOD',
      value: method,
    } as const;
  },
  setSelectedOfferUuid: (uuid: string) => {
    return {
      type: 'SET_OFFER_UUID',
      value: uuid,
    } as const;
  },
  setSelectedChild: (child: string) => {
    return {
      type: 'SET_SELECTED_CHILD',
      value: child,
    } as const;
  },
  clearPayment: () => {
    return {
      type: 'CLEAR_PAYMENT',
    } as const;
  },
};

export const selectors = {
  paymentMethod: (state: RootStore) => state.payment.method,
  noInsuranceSelected: (state: RootStore) => state.payment.method === PAYMENT_METHOD.OUT_OF_POCKET,
};

// takes action types from action creators
type Action = ReturnType<typeof actions[keyof typeof actions]>

export const initialState = {
  method: PAYMENT_METHOD.NO_CHOICE,
  // below is existing before state, we need to revisit it sense
  offerUUIDSelected: null,
  childSelected: null,
};

// eslint-disable-next-line default-param-last
export const reducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'SET_PAYMENT_METHOD':
      return { ...state, method: action.value };
    case 'SET_OFFER_UUID':
      return {
        ...state,
        offerUUIDSelected: action.value,
      };
    case 'SET_SELECTED_CHILD':
      return {
        ...state,
        childSelected: action.value,
      };
    case 'CLEAR_PAYMENT':
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
