import PlushcareWebAPI from './api';
import { LocalStorage } from './storage';

/**
 * Wrapper around geo-location utility functions.
 */
class GeoUtils {
  /**
   * Returns location data.
   * @param {*} force tells to ignore cached data and to always request API.
   */
  static async retrieveLocation(force) {
    let locationData = LocalStorage.getItem('plushcare.geo.location');
    if (!locationData || force) {
      const { data: { location_data } } = await PlushcareWebAPI.get('/services/geo_location/', null, true);
      locationData = location_data;
    }

    if (locationData) {
      LocalStorage.setItem('plushcare.geo.location', locationData, 30);
    } else {
      LocalStorage.removeItem('plushcare.geo.location');
    }
    return locationData;
  }

  /**
   * Returns `{ name, value }` ie `{ name: 'California', value: 'CA' }` dictionary.
   * Tries to read local storage first.
   * @param {bool} force tells to ignore cached data and to always request API.
   */
  static async retrieveState(force) {
    let stateData = LocalStorage.getItem('plushcare.geo.state');

    if (stateData && !force) {
      return stateData;
    }

    const locationData = await this.retrieveLocation(force);
    if (locationData) {
      stateData = {
        name: locationData.region,
        value: locationData.region_code,
      };
      this.saveState(stateData);
    } else {
      stateData = {
        name: this.fallbackLocation.region,
        value: this.fallbackLocation.region_code,
      };
      LocalStorage.removeItem('plushcare.geo.state');
    }

    return stateData;
  }

  static saveState(stateData) {
    LocalStorage.setItem('plushcare.geo.state', stateData, 30);
  }

  static fallbackLocation = {
    region: 'California',
    region_code: 'CA',
    city: 'San Francisco',
    lat: '37.775758',
    lng: '-122.397982',
  };
}

export default GeoUtils;
