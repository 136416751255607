import styled from '@emotion/styled';
import ButtonPair from 'src/js/nextgen/plushcare-components/ButtonPair';
import { Button, colors } from 'src/js/nextgen/plushcare-components';
import ADSModal, { ModalHeader as ADSModalHeader } from 'src/js/components/ADSModal';

type modalSize = 'lg' | 'sm' | 'xl';
type modalButtonGroup = 'primary' | 'secondary' | 'none';
interface ModalProps {
    isOpen: boolean
    toggle?:React.KeyboardEventHandler<any> | React.MouseEventHandler<any>,
    title?: string
    className: string
    primaryDisabled?: boolean,
    primaryClick?: () => void,
    primaryText?: string
    secondaryText?: string
    children: React.ReactNode
    size?: modalSize
    buttonGroup: modalButtonGroup
    closeIcon: boolean
    buttonDirection?: 'flex-end' | 'flex-start' | 'center' | 'space-between'
}

export const modal = ({
  isOpen, toggle, className, children,
  title, buttonGroup, primaryDisabled, primaryClick,
  primaryText, secondaryText, closeIcon,
}: ModalProps) => {
  const noHeader: boolean = !title;
  return (
    <ModalWrapper
      isOpen={isOpen}
      className={`${className} ${noHeader && 'no-header'}`}
      contentClassName=""
    >
      {title && (
        <ModalHeader>
          <h5>{title}</h5>
        </ModalHeader>
      )}
      {(closeIcon && toggle) && (<CloseIcon onClick={toggle} />)}
      <ModalContent>
        {children}
      </ModalContent>
      {(buttonGroup === 'secondary') && (
        <ModalFooter>
          <ButtonPair
            direction="horizontal"
            order="reverse"
            primaryClick={primaryClick}
            primaryText={primaryText}
            primaryTextSize="14px"
            tertiaryClick={primaryClick}
            tertiaryText={secondaryText}
            tertiaryTextSize="14px"
          />
        </ModalFooter>
      )}
      {(buttonGroup === 'primary') && (
        <ModalFooter>
          <Button onClick={primaryClick} isDisabled={primaryDisabled} type="primary">
            {primaryText}
          </Button>
        </ModalFooter>
      )}

    </ModalWrapper>
  );
};

const Modal = styled(modal)`
`;

const ModalWrapper = styled(ADSModal)`
  height: 100vh;
  align-items: center;
  position: fixed;
  .modal-content {
    background-color: ${colors.white};
    border-radius: 4px;
    border: 1px solid ${colors.borderGray};
  }

  &.no-header  {
    div {
      border-top: 0;
      border-bottom: 0;
    }
  }
`;

const ModalHeader = styled(ADSModalHeader)`
  justify-content: left;
  display: flex;
  font-size: 20px;
  border-bottom: 1px solid ${colors.borderGray};
  padding-left: 15px;
`;

const ModalContent = styled.div`
  text-align: center;
  display: block;
  margin: 25px 25px 0 25px;
`;

const ModalFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;

  padding: 0.75rem;
  border-top: 1px solid ${colors.borderGray};
  &.no-border {
    border-top: 0px;
  }
`;

const CloseIcon = styled.div`
  position: absolute;
  right: 20px;
  top: 10px;
  width: 20px;
  height: 20px;
  opacity: 0.3;

  :hover {
    opacity: 1;
  }
  :before,:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 20px;
    width: 1px;
    background-color: ${colors.darkParagrahGray};
  }
  :before {
    transform: rotate(45deg);
  }
  :after {
    transform: rotate(-45deg);
  }
`;

export default Modal;
