import React from 'react';
import { TotalBar, PercentageBar } from './ContentProgressBar.styled';

const ContentProgressBar = ({ total, progress }) => {
  const percentage = `${Math.min(100, 100 * (progress / total))}%`;

  return (
    <>
      <PercentageBar percentage={percentage} />
      <TotalBar />
    </>
  );
};

export default ContentProgressBar;
