import React from 'react';
import styled from '@emotion/styled';
import { colors } from 'src/js/nextgen/plushcare-components/helpers/plushcare-style-defaults';
import { STD_ERROR_MSG } from 'src/js/nextgen/utils/constants';

const ErrorMessageStyled = styled.div`
  min-height: 15px;
  font-size: 12px;
  line-height: 15px;
  color: ${colors.adaRed};
  text-align: left;
`;

const ErrorMessage: React.FC<React.PropsWithChildren<{message?: string, customStyles?: {[key: string]: string}}>> = ({
  message = STD_ERROR_MSG, customStyles, ...props
}) => (
  <>
    { message
      && (
      <ErrorMessageStyled className="error-message" style={customStyles} {...props}>
        {message}
      </ErrorMessageStyled>
      )}
  </>
);

export default ErrorMessage;
