import styled from '@emotion/styled';
import { colors } from '../helpers/plushcare-style-defaults';

export default styled.div`
  padding: 12px 25px 9px;
  margin: 20px 20px 10px;
  color: ${colors.white};
  font-weight: 500;
  background-color: ${colors.backgroundGray};
  border-radius: 8px;
  z-index: 1;
`;
