/* eslint-disable */
// TODO:: deprecate potentially
import {
  SET_LOCATION_COORDINATES,
} from '../actionTypes/BookingActionType';
import {
  SELECT_LOCATION,
} from '../actionTypes/LocationActionTypes';

export const setCoordinates = ({ address, latitude, longitude }) => ({
  type: SET_LOCATION_COORDINATES,
  address,
  latitude,
  longitude,
});

export const setSelectedLocation = selectedLocation => ({
  type: SELECT_LOCATION,
  selectedLocation,
});

export const getGeoLocation = (address, location) => ((dispatch) => {
  if (address) {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        const { geometry: { location }, formatted_address } = results[0];
        dispatch(setCoordinates({ address: formatted_address, latitude: location.lat(), longitude: location.lng() }));
      } else {
        dispatch(setError('Can\'t determine location'));
      }
    });
  } else {
    dispatch(setCoordinates({ address: location.city, latitude: location.latitude, longitude: location.longitude }));
  }
});
