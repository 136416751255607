/* global google */
import {
  SET_HOME_ADDRESS,
  SET_PHARMACY_ADDRESS,
  SET_APPOINTMENT_DATA,
  SET_CLEAR_PHARMACY_STORE,
  SET_PHARMACY_LIST,
  SET_SPECIALTY,
  SET_POST_SUCCESS,
  SET_LIST_LOADING,
  SET_LOADING,
  SET_ERROR,
} from '../actionTypes/BookingActionType';

import {
  setCoordinates,
} from './LocationActions';

import {
  PlushcareWebAPI, V2Response, appUrl, updateLocation, apiUrl, SessionStorage,
} from '../utils';

export function clearPharmacyStore() {
  return {
    type: SET_CLEAR_PHARMACY_STORE,
  };
}

export function setHomeAddress(homeAddress) {
  return {
    type: SET_HOME_ADDRESS,
    homeAddress,
  };
}

export function setSpecialty(specialty) {
  return {
    type: SET_SPECIALTY,
    specialty,
  };
}

export function setPharmacyAddress(pharmacyAddress, isExisting = false) {
  return {
    type: SET_PHARMACY_ADDRESS,
    pharmacyAddress,
    isExisting,
  };
}

export function setAppointmentData(appointmentData) {
  return {
    type: SET_APPOINTMENT_DATA,
    appointmentData,
  };
}

export function setLoading(isLoading) {
  return {
    type: SET_LOADING,
    isLoading,
  };
}

export function setPostSuccessful(isSuccessful) {
  return {
    type: SET_POST_SUCCESS,
    isSuccessful,
  };
}

export function setListLoading(isListLoading) {
  return {
    type: SET_LIST_LOADING,
    isListLoading,
  };
}

export function setError(error) {
  return {
    type: SET_ERROR,
    error,
  };
}

export function setPharmacyList(pharmacyList) {
  return {
    type: SET_PHARMACY_LIST,
    pharmacyList,
  };
}

export const updateLocationCoordinates = (address, latitude, longitude) => ((dispatch) => {
  dispatch(setCoordinates({ address, latitude, longitude }));
});

const getPharmacyListRequest = ({
  currentLatitude, currentLongitude, specialty,
}, zoom = 12) => ((dispatch) => {
  dispatch(setListLoading(true));
  dispatch(setSpecialty(specialty));
  const postData = {
    latitude: currentLatitude,
    longitude: currentLongitude,
  };

  if (specialty) {
    postData.specialty = specialty;
  }

  PlushcareWebAPI.post(apiUrl.getPharmacies, postData, true, true)
    .then((resp) => {
      const v2 = new V2Response(resp);
      if (!v2.error) {
        dispatch(setPharmacyList(v2.payload.pharmacy_list.slice(0, (-zoom + 25))));
        dispatch(setListLoading(false));
      } else {
        dispatch(setError(v2.errorMessage));
      }
    })
    .catch((error) => {
      const statusText = ('statusText' in error) ? error.statusText : 'Error getting pharmacies near you';
      dispatch(setError(statusText));
      dispatch(setListLoading(false));
    });
});

export const getPharmacyList = ({
  currentLatitude, currentLongitude, specialty,
}, zoom) => ((dispatch) => {
  dispatch(getPharmacyListRequest({ currentLatitude, currentLongitude, specialty }, zoom));
});

export const getPharmacyAndHomeAddress = () => (async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const { data: { address, pharmacy } } = await PlushcareWebAPI.get(
      'patients/address/',
      null,
      true,
    );

    const pharmacyAddress = (pharmacy) || null;
    dispatch(setHomeAddress(address));
    dispatch(setPharmacyAddress(pharmacyAddress, true));

    const homeAddress = (address && address.state)
      ? `${address.street_address},${address.city},${address.state.abbreviation} ${address.zip_code}, USA`
      : null;
    if (homeAddress) {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: homeAddress }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const { geometry: { location }, formatted_address } = results[0];
          dispatch(setCoordinates({ address: formatted_address, latitude: location.lat(), longitude: location.lng() }));
          dispatch(getPharmacyListRequest({ currentLatitude: location.lat(), currentLongitude: location.lng() }));
        } else {
          dispatch(setError('Can\'t determine location'));
        }
      });
    } else {
      const { data: { location_data: location } } = await PlushcareWebAPI.get('/services/geo_location/', null, true);
      dispatch(setCoordinates({ address: location.city, latitude: location.lat, longitude: location.lng }));
      dispatch(getPharmacyListRequest({ currentLatitude: location.lat, currentLongitude: location.lng }));
    }

    dispatch(setLoading(false));
  } catch (error) {
    const statusText = ('statusText' in error) ? error.statusText : 'Error getting your address';
    dispatch(setError(statusText));
    dispatch(setLoading(false));
  }
});

export const getFilteredPharmacyList = ({
  organization, currentLatitude, currentLongitude,
}) => ((dispatch) => {
  dispatch(setListLoading(true));
  PlushcareWebAPI.post(apiUrl.getPharmacies, {
    organization,
    latitude: currentLatitude,
    longitude: currentLongitude,
  }, true, true).then((resp) => {
    const v2 = new V2Response(resp);
    if (!v2.error) {
      dispatch(setPharmacyList(v2.payload.pharmacy_list));
      dispatch(setListLoading(false));
    } else {
      dispatch(setError(v2.errorMessage));
    }
  }).catch((error) => {
    const statusText = ('statusText' in error) ? error.statusText : 'Error getting pharmacies near you';
    dispatch(setError(statusText));
    dispatch(setListLoading(false));
  });
});

export const postHomeAddress = ({
  homeAddress,
}) => (async (dispatch) => {
  dispatch(setLoading(true));

  try {
    await PlushcareWebAPI.post(
      'patients/address/',
      {
        address: homeAddress.streetAddress,
        ...(homeAddress.streetAddressTwo && { address_two: homeAddress.streetAddressTwo }),
        city: homeAddress.city,
        state: homeAddress.state,
        zip: homeAddress.zipCode,
      },
      false,
      true,
    );
    dispatch(setLoading(false));
    dispatch(setPostSuccessful(true));
    updateLocation(appUrl.booking.survey);
  } catch (error) {
    const statusText = ('statusText' in error) ? error.statusText : 'Error saving your address';
    dispatch(setError(statusText));
    dispatch(setLoading(false));
    dispatch(setPostSuccessful(false));
  }
});

export const postPharmacy = (pharmacyAddress, nextUrl) => ((dispatch) => {
  dispatch(setLoading(true));

  const postData = {
    address: pharmacyAddress.address_line_one,
    city: pharmacyAddress.city,
    zip: pharmacyAddress.zip_code,
    state: pharmacyAddress.state,
    ncpdp_id: pharmacyAddress.ncpdp_id,
    user_only: false,
  };
  PlushcareWebAPI.post(apiUrl.patients.setUserPharmacy, postData, false, true).then(() => {
    const isEligibleForExpectations = SessionStorage.getItem('is_eligible_for_expectations');
    const isEligibleForQuestionnaire = SessionStorage.getItem('is_eligible_for_wm_questionnire');
    dispatch(setLoading(false));
    if (window.location.href.includes('check-in')) {
      // Check-in flow
      dispatch(setPharmacyList(null));
      updateLocation(appUrl.checkIn.files);
    } else if (!window.location.href.includes('edit')) {
      // Booking flow
      dispatch(setPharmacyList(null));
      if (nextUrl) {
        updateLocation(nextUrl);
      } else if (isEligibleForExpectations || isEligibleForQuestionnaire) {
        updateLocation(appUrl.booking.weightManagement.confirmAppointmentType);
      } else {
        updateLocation(appUrl.booking.selectAddress);
      }
    } else {
      // Profile page
      dispatch(setPharmacyAddress(pharmacyAddress, true));
    }
  }).catch((error) => {
    const statusText = ('statusText' in error) ? error.statusText : 'Error saving your pharmacy';
    dispatch(setError(statusText));
    dispatch(setLoading(false));
  });
});
