import { GetServerSidePropsContext } from 'next';
import { serverGet } from 'src/js/utils/apiV2';
import { PlushcareWebAPI } from 'src/js/utils';
import Analytics from 'src/js/utils/analytics/Analytics';

export type LoginHealth = {
  auth_session: boolean,
  token_active: boolean,
  reset_timer: boolean,
  logout_client: boolean,
  backend_forced_logout: boolean,
  session_expire: string,
}

export const getLoginHealth = (reset: boolean) => PlushcareWebAPI.post(
  '/auth/loginhealth/',
  { reset_timer: reset ? 'True' : 'False' },
  false,
  true,
)
  .then(resp => resp)
  .catch((e) => { Analytics.otherException(e); });

export const getLoginHealthServer = (req: GetServerSidePropsContext['req']): Promise<{data: LoginHealth, error: Error}> => PlushcareWebAPI.staticServerPost(
  {
    url: '/auth/loginhealth/',
    payload: {},
    cookie: req.headers.cookie,
    token: req.cookies?.token,
  },
);

export const checkToken = (req: GetServerSidePropsContext['req']) => serverGet<{token_active: boolean}>({
  url: '/v2/auth/token/check',
  cookies: req.cookies,
  headerCookies: req.headers.cookie,
});
