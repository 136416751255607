/* eslint-disable max-classes-per-file */
/* eslint-disable no-console */

/**
 * BASE CLASS Storage
 * this class is a base class for LocalStorage and SessionStorage
 * it defines getItem and setItem based on the children storage.
 */

export class Storage {
  /** init is responsible of overriding the Storage prototype methods,
   * wrapping them in error handling, and pushing errors to Sentry,
   * and calling the original prototype methods for the actual get, set, remove.
   */
  static init() {
    if (!this.isStorageEnabled()) {
      return;
    }

    const origGetItem = window.Storage.prototype.getItem;
    const origSetItem = window.Storage.prototype.setItem;
    const origRemoveItem = window.Storage.prototype.removeItem;

    window.Storage.prototype.getItem = function getItem(key) {
      try {
        return origGetItem.apply(this, [key]);
      } catch (err) {
        console.error(`Error: Unable to read a key ${key} from Storage`, err);
      }
      return undefined;
    };

    window.Storage.prototype.setItem = function setItem(key, value) {
      try {
        origSetItem.apply(this, [key, value]);
      } catch (err) {
        console.error(`Error: Unable to set a key ${key} from Storage`, err);
      }
    };

    window.Storage.prototype.removeItem = function removeItem(key) {
      try {
        origRemoveItem.apply(this, [key]);
      } catch (err) {
        console.error(`Error: Unable to remove a key ${key} from Storage`, err);
      }
    };
  }

  /**
   * Returns a value for the param `key`. Checks expiration time if it was set up.
   * if stored value is null or undefined, returns undefined.
   * if not JSON.parse value and returns it if is not expired, otherwise return undefined.
   */
  static getItem(key) {
    if (!this.isStorageEnabled()) {
      return undefined;
    }

    let rawValue;
    try {
      rawValue = this.storage.getItem(key);
    } catch (err) {
      console.error(`Error getting key ${key} from redefined getItem method in Storage`, err);
      return undefined;
    }

    if (rawValue === null || rawValue === undefined) {
      return undefined;
    }

    // parsing data and checking if exists expirationDate and if its still valid  return value or undefined otherwise.
    let data;
    try {
      data = JSON.parse(rawValue) || {};
    } catch {
      data = {};
    }

    const { e: expires, v: value, $ver$: version } = data;

    if (version === undefined) {
      return rawValue;
    }
    try {
      if (expires && new Date(Date.parse(expires)) <= new Date()) {
        return undefined;
      }
    } catch {
      return undefined;
    }
    return value;
  }

  /**
   * sets item for  param `key` with `value`.
   * if expires
   * if not JSON.parse value and returns it if is not expired, otherwise return undefined.
   */
  static setItem(key, v, expires = undefined) {
    if (!this.isStorageEnabled()) {
      return;
    }

    let e;
    if (expires) {
      e = new Date();
      e.setDate(e.getDate() + expires);
    }

    const payload = {
      $ver$: 1,
      v,
      e,
    };

    try {
      this.storage.setItem(key, JSON.stringify(payload));
    } catch (err) {
      console.error(`Error adding key ${key} from redefined setItem method in Storage`, err);
    }
  }

  /**
   * Removes `key` from the storage
   * @param {*} key storage key
   */
  static removeItem(key) {
    try {
      this.storage.removeItem(key);
    } catch (err) {
      console.error(`Error removing key ${key} from redefined removeItem method in Storage`, err);
    }
  }

  static clear() {
    try {
      this.storage.clear();
    } catch (err) {
      console.error('Error calling storage.clear() from redefined clear method in Storage', err);
    }
  }
}

export class SessionStorage extends Storage {
  storage; // defining it in parent class wont work

  // If I move this method to parent class it fails. why?
  static isStorageEnabled = () => {
    try {
      this.storage.setItem('session_storage_key', 42);
      this.storage.removeItem('session_storage_key');
      return true;
    } catch (e) {
      return false;
    }
  };

  static init() {
    try {
      this.storage = ('sessionStorage' in window && window.sessionStorage !== null) ? window.sessionStorage : null;
    } catch (e) {
      this.storage = null;
    }
    super.init();
  }
}

export class LocalStorage extends Storage {
  storage; // defining it in parent class wont work

  // we need to check this because  # 'localStorage' in window == true # when user
  // has disabled caching or cookies in the browser. Same for sessionStorage
  static isStorageEnabled = () => {
    try {
      this.storage.setItem('local_storage_key', 42);
      this.storage.removeItem('local_storage_key');
      return true;
    } catch (e) {
      return false;
    }
  };

  static init() {
    try {
      this.storage = ('localStorage' in window && window.localStorage !== null) ? window.localStorage : null;
    } catch (e) {
      this.storage = null;
    }
    super.init();
  }
}

LocalStorage.init();
SessionStorage.init();

export function loadStore(storeNamespace) {
  try {
    const serializedState = sessionStorage.getItem(storeNamespace);
    if (serializedState === null) {
      return null;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return null;
  }
}

export function saveStore(state, storeNamespace) {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem(storeNamespace, serializedState);
  } catch (e) {
    // we don't care about errors atm. This may happen if user is in Incognito mode for instance
  }
}
