import DOMPurify from 'dompurify';
import PlushcareWebAPI from './api';

export const NO_SERVER_RESPONSE = {
  message: 'No server response',
  code: 'NO_RESPONSE',
};

export interface V2Success<T> {
    status: 'success',
    payload: T
}

export interface V2Error<E = null> {
    status: 'error',
    error: {
        code: string,
        code_alt: string,
        message: string,
        error_data: E | null // standard errors will always send null here
    }
}

export type V2Response<T, E = null> = V2Success<T> | V2Error<E>;

// destructors into bool
export const isSuccess = <T, E>(resp: V2Response<T, E>): resp is V2Success<T> => resp.status === 'success';
export const isError = <T, E>(resp: V2Response<T, E>): resp is V2Error<E> => resp.status === 'error';

// value constructors
export const error = <E>(err: V2Error<E>['error']): V2Error<E> => ({
  status: 'error',
  error: err,
});
export const success = <T>(payload: T): V2Success<T> => ({
  status: 'success',
  payload,
});

export const post = async <T, R, E = null>(url: string, data: T): Promise<V2Response<R, E>> => {
  try {
    const res = await PlushcareWebAPI.apiPost(url, data, true);
    return res.data;
  } catch (e: Error|any) {
    return e?.response?.data ?? noResponseError;
  }
};

export const get = async <R, E = null>(url: string, params?: Record<string, any>): Promise<V2Response<R, E>> => {
  try {
    const res = await PlushcareWebAPI.apiGet(url, params);
    return res.data;
  } catch (e: Error|any) {
    return e?.response?.data ?? noResponseError;
  }
};

export const noResponseError: V2Error = error({
  code: NO_SERVER_RESPONSE.code,
  code_alt: 'NR',
  message: NO_SERVER_RESPONSE.message,
  error_data: null,
});

export const customError = (message: string) => error({
  code: 'CUSTOM',
  code_alt: 'CS',
  message,
  error_data: null,
});

export type ServerGetParams = {
  headerCookies?: string
  cookies: { token?: string, timezone_offset?: string }
}
// used to fire GET requests from Nextjs "getServerSideProps" hook
export const serverGet = async <R, E = null>({
  url,
  headerCookies,
  cookies,
}: { url: string } & ServerGetParams): Promise<V2Response<R, E>> => {
  try {
    const res = await PlushcareWebAPI.staticServerGet({
      url,
      cookie: headerCookies,
      token: cookies?.token,
      customHeaders: { timezone_offset: cookies?.timezone_offset },
    });
    return res.data;
  } catch (e: Error|any) {
    return e?.response?.data ?? noResponseError;
  }
};

export const sanitizeStr = (str: string): string => {
  return DOMPurify.sanitize(str);
};
