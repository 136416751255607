/* eslint-disable max-len */
import { RootStore } from 'src/js/store/GlobalStore';
import { UserFeatureFlags, UserSubscription } from 'src/js/reducers/UserReducer';
import { PharmacyState } from 'src/js/reducers/PharmacyReducer';
import { LocationState } from 'src/js/reducers/LocationReducer';
import { ReschedulingAppointment } from 'src/js/globalTypes';
// import { USER_ORIGIN } from 'src/js/nextgen/utils/constants';
import { defaultUrlFilterData } from 'components/booking/ClinicianFirst/Search/searchTypes';

export const isLoggedIn = (state: RootStore) => !!state.user?.isLoggedIn;
export const isReschedule = (state: RootStore) => !!state?.reschedule?.isReschedule;
export const getStoredUser = (state: RootStore) => state.user;
export const getStoredUserPharmacy = (state: { pharmacy?: PharmacyState }) => state.pharmacy;
export const hasTherapySubscription = (state: RootStore) => !!state.user?.subscriptions?.some((sub: UserSubscription) => sub.sub_type === 'therapy' && sub.is_active);
export const hasAnyActiveSubscription = (state: RootStore) => state.user?.subscriptions?.some((sub: UserSubscription) => sub.is_active);
export const preBookedAppointment = (state: RootStore) => state.booking?.preBookedAppointment ?? null;
export const getLocation = (state: { location: LocationState }) => state.location;
export const getRescheduledAppt = (state: { reschedule: ReschedulingAppointment }): ReschedulingAppointment => state?.reschedule;
export const getInsurance = (state: RootStore) => state?.insurance;
export const getFlowType = (state: RootStore) => state?.booking?.flowType;
export const getStoredSource = (state: RootStore) => state.source;
export const getClinicianFirstFilterData = (state: RootStore) => state.booking?.filterData ?? { ...defaultUrlFilterData };
export const getClinicianFirstClinician = (state: RootStore) => state.booking?.clinician ?? null;
export const isUserEligible = (state: RootStore) => getInsurance(state).coverageType === 'eligible';
export const hasFitbitStudy = (state: RootStore): boolean => getStoredSource(state)?.isFitbitStudy;
export const getPayment = (state: RootStore) => state.payment;
export const hasToConfirmProfile = (/* state: RootStore */) => {
  /**
   * TODO: SSO users with `phonenumber_origin` set to `Partner` would be stuck in booking flow after they selected
   * an appoitment, they would be redirected to the profile confirmation page.
   * https://accoladeinc.atlassian.net/browse/EE-1737
   *
   * Follow-up ticket to resolve: https://accoladeinc.atlassian.net/browse/EE-1738
   */
  return false;
  /*
  const user = getStoredUser(state);
  return user && user.phoneNumberOrigin && user.phoneNumberOrigin.toLowerCase() !== USER_ORIGIN;
  */
};
export const getUserFeatureFlags = (state: RootStore) => {
  return state.user?.userFeatureFlags ?? {} as UserFeatureFlags;
};
