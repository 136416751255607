export const apiUrl = {
  actionables: {
    all: '/pushnotification/actionable/all/',
    unexpired: '/pushnotification/actionable/unexpired/all/',
    followups: '/pushnotification/actionable/unexpired/all/?namespaces=NextAppointment::Preventative::AWV,NextAppointment',
  },
  getUserData: '/patients/users/retrieve/',
  getDashboardData: '/chameleon/dashboard/',
  getUserSubscriptions: '/chameleon/dashboard/subscriptions/',
  getPCPData: '/patients/pcp/',
  getAppointmentDates: '/ver1/booking/available/dates/',
  getAppointments: '/ver2/booking/available/appointments/',
  getTherapists: '/ver2/booking/therapists/',
  getTherapistDetail: (doctor_id: string) => `ver2/booking/therapists/${doctor_id}/`,
  getTherapyAppointments: '/ver2/booking/available/therapy/appointments',
  getTherapyDates: '/ver1/booking/available/therapy/dates',
  getTopPayers: '/eligible/fetch/names',
  postCancelAppointment: '/ver1/booking/cancel/',
  getAppointmentPrice: '/v2/appointments/price',
  appointmentCheckIn: '/v2/appointments/checkin/',
  appointmentCheckInLabs: '/v2/appointments/checkin/labs/',
  postMembership: '/subscription/member/join/',
  getPharmacies: '/v2/pharmacy/list_by_geo',
  booking: {
    bookPrimary: '/ver2/booking/book_primary/',
    bookPreventative: '/ver2/booking/preventative/book/',
  },
  patients: {
    appointment: '/ver1/booking/available/appointment/',
    appointments: '/patients/appointments/',
    appointmentFeedback: '/patients/appointment/submit/feedback/',
    appointmentsByDoctor: '/ver1/booking/appointments-by-doctor/',
    afterCallFeedback: '/patients/appointment/aftervideocall/',
    createUser: '/patients/ver1/create-user/',
    doctorInfo: '/patients/doctors/',
    roiOptions: '/patients/appointment/aftervideocall/roi/',
    patientMedicalRecords: '/v2/patient/medical_records/',
    appointmentHold: '/patients/appointment/hold/',
    appointmentReleaseHold: '/patients/appointment/release-unbooked-hold/',
    retrieve: '/patients/users/retrieve/',
    logout: 'patients/users/logout/',
    setUserAddress: 'patients/address/',
    uspsvalidatebyzip: '/patients/address/uspsvalidatebyzip',
    availableAppointments: '/patients/appointments/available/new/',
    setUserPharmacy: '/patients/pharmacy/',
    updateUser: '/patients/users/update/',
    editUser: '/patients/users/edit/',
    attachPaymentMethod: '/patients/users/paymentmethods/attach/',
    updateUserEmail: '/patients/users/update/email/',
    getCoverage: '/v2/users/profile/coverage/',
    disclaimerAcknowledge: '/v2/users/',
    invite: (inviteId: string) => `/patients/invite/${inviteId}`,
    inviteAccept: (inviteId: string) => `/patients/invite/accept/${inviteId}`,
    inviteValidate: (inviteId: string) => `/patients/invite/validate/${inviteId}`,
    getOffers: '/v2/patient/offers',
    lateCancelNoShow: '/ver1/booking/therapy/patient/cancellations/',
    sendDownloadAppLink: '/pushnotification/text/app/',
    nextAvailableDoctors: '/v2/patient/appointments/next_available_doc_state',
    uploadFile: (appointment_uuid: string) => `/v2/patient/appointment/${appointment_uuid}/upload/files/`,
    cancelRecurringPlan: '/ver1/booking/therapy/recurring-plan/cancel/',
    getCancellationReasosn: 'ver1/booking/cancellation-reasons/',
    getChildren: '/patients/users/children/',
    createChild: 'patients/users/child/',
    updateChild: (child_id: number) => `patients/users/child/${child_id}/update/`,

  },
  billing: {
    card: '/v2/billing/card/',
  },
  promotions: {
    shareableLink: (encoded_appointment_id: string) => `/promotions/retrieve/shareable_link/${encoded_appointment_id}/`,
    campaign: '/promotions/campaign',
  },
  tasks: {
    followupAppointmentTask: '/pushnotification/actionable/nextappointment/complete/',
  },
  inAppNotifications: {
    getNotifications: '/pushnotification/notification/all/',
    setInteracted: '/pushnotification/notification/interacted/',
    setDismiss: '/pushnotification/notification/dismiss/',
  },
  cancellation: {
    cancel: '/subscription/member/cancel/',
    reasons: '/subscription/member/cancel/reasons/',
  },
  survey: {
    getQuestionnaireUUID: '/surveys/questionnaire/active/', // This is hitting the new survey engine api.
    getActiveQuestionnaireUUID: '/survey/active/', // This is hitting the old survey api. The URL above will replace this.
    start: (uuid: string) => `/survey/start/${uuid}/`,
    answer: '/survey/single-answer/',
    answerMultiple: '/survey/answer/',
    complete: '/survey/questionnaire/complete/',
    results: (uuid: string) => `/survey/results/${uuid}/`,
    questionnaire: (questionnaireUUID: string, latest: boolean = false) => `/survey/questionnaire/${questionnaireUUID}/${latest ? '?latest=true' : ''}`,
    userQuestionnaire: (userQuestionnaireUUID: string) => `/survey/questionnaire/user/${userQuestionnaireUUID}/`,
    generalQuestions: '/chameleon/aha/questionnaire/general/',
    historyQuestionnaire: '/chameleon/aha/questionnaire/history/',
    symptomsQuestions: '/chameleon/aha/questionnaire/symptoms/',
    phqQuestions: '/chameleon/aha/questionnaire/phq/',
    gadQuestions: '/chameleon/aha/questionnaire/gad/',
    lifestyleQuestions: '/chameleon/aha/questionnaire/lifestyle/',
    treatmentQuestions: '/chameleon/aha/questionnaire/treatment/',
  },
  getTherapyAppointmentPrices: '/ver1/pricing/therapy',
  getTherapyActiveRecurringPlan: '/ver1/booking/therapy/active-recurring-plan/',
  getVisitHistoryData: '/chameleon/appointments/',
  getPrescriptionsData: '/chameleon/prescriptions/',
  getTestAndResultsData: '/chameleon/orders/',
  getVitalsData: '/chameleon/profile/',
  getTherapyCheckinStatus: '/v2/therapy-checkin/check',
  getInsuranceEligibility: '/v2/collaborative-care-management/patient/eligibility-check/',
  enterprise: {
    authCallback: '/partners/auth/callback/',
    link: '/patients/users/enterprise/',
    check: '/patients/users/enterprise/check/',
    partnerLogin: '/partners/user/login/',
    eap: '/v2/eap/available/',
    newSSOUser: '/partners/user/new_sso/',
    confirmSSO: '/partners/user/confirm/',
  },
  sockomatic: {
    authToken: '/v2/auth/token/',
  },
  treecko: {
    consentForms: '/treecko/consent_forms/',
    consentFormStatus: '/treecko/consent_forms/status/',
    consentFormStatusUpdate: (consentFormUuid: string) => `/treecko/consent_forms/${consentFormUuid}/status/`,
  },
  logVideoEvent: '/doctor/log_video_connection_status/',
  connectionStatus: '/doctor/get_video_connection_events_appointment/',
  clinicianFirst: {
    clinicianSearch: '/ver1/booking/doctors/',
  },
  medications: {
    search: '/v2/patient/medication/search/',
    add: '/v2/patient/medication/add/',
    get: '/v2/patient/medication/get/',
  },
  preventative: {
    annual: {
      eligible: '/ver2/booking/preventative/eligible/',
      book: '/ver2/booking/preventative/book/',
      recordPreviousVisit: '/ver2/booking/preventative/record/previous/',
    },
  },
  partnersBrand: '/partners/brand/',
  specialist: {
    specialists: '/ver1/booking/doctors/specialists/',
    specialties: '/ver1/booking/doctors/specialists/specialties/',
    requestAppointment: '/ver1/booking/doctors/specialists/request/',
  },
  password: {
    reset: '/auth/accounts/password/reset/',
    resetConfirm: '/auth/accounts/password/reset/confirm/',
    resetEmail: '/auth/accounts/password/reset/email/',
  },
  payments: {
    createPaymentIntent: '/ver1/pricing/payment-intent/',
    getPaymentInformationVer2: '/ver2/stripe/default-payments/',
  },
  pricing: {
    primaryCare: {
      getPrice: '/ver1/pricing/primary-care/',
      getDefaultUninsuredPrice: '/ver1/pricing/primary-care/default-uninsured/',
    },
    tm2u: {
      copayEstimate: '/v2/pricing/specialists/',
    },
  },
  weightManagement: {
    getEligibility: (child_id: number | null) => `/v2/wm/eligible/?child_id=${child_id || ''}`,
    getInsuranceInformation: '/v2/wm/pa_insurance_on_file/',
    postExpectationsViewed: '/v2/wm/expectations/',
    submitQuestionnaire: (child_id: number | null) => `/v2/wm/prior_authorization/?child_id=${child_id || ''}`,
  },
  prescriptionsHistory: {
    all: '/patients/prescriptions',
    receipt: '/chameleon/rx_encounters',
  },
  messaging: {
    channels: '/v2/patient/messaging/channels',
  },
  analytics: {
    commonAttributes: '/v2/analytics/common-attributes/',
  },
  appointments: {
    asyncCoverageRelease: '/v2/appointments/async_coverage_check/release',
  },
  insurance: {
    updateMemberId: '/v2/insurance/update-member-id/',
  },
} as const;

export const appUrl = {
  login: '/login/',
  dashboard: '/myhealth/dashboard/',
  upcomingAppointments: '/myhealth/dashboard/upcoming-appointments/',
  AHAPrompt: '/myhealth/dashboard/health-assessment',
  feedback: {
    thankyou: '/feedback/thankyou',
    referral: (encoded_appointment_id: string) => `/feedback/referral/${encoded_appointment_id}`,
  },
  authentication: {
    ssoEntry: '/authentication/sso-entry/',
    healthplan: '/authentication/select-healthplan/',
  },
  testConnection: '/myhealth/dashboard/ready',
  joinAppointment: '/myhealth/dashboard/call',
  therapyCheckInIntro: '/myhealth/therapy/check-in/intro/',
  beforeTherapyAptCheckinStart: '/myhealth/therapy/check-in/1/',
  beforeTherapyAptCheckinResult: '/myhealth/therapy/check-in/result/',
  booking: {
    init: '/booking/primary-care/appointments',
    // used as 1st step for Therapy/Rx enrolled users
    selectAppointmentType: (type: string) => `/booking/select-appointment-type/${type}/`,
    method: '/booking/primary-care/method/',
    pcInit: '/booking/primary-care/appointments',
    appointments: '/booking/primary-care/appointments',
    insurance: '/booking/primary-care/insurance',
    insuranceConfirmation: '/booking/primary-care/insurance/confirmation',
    insurancePending: '/booking/primary-care/insurance/pending',
    selectState: '/booking/primary-care/select-state/',
    selectVisitReason: '/booking/primary-care/select-visit-reason/',
    appointmentsByDoctor: '/booking/primary-care/schedule/',
    clinicianFirst: {
      clinicianSearch: '/booking/clinician-first/clinician-search/',
      clinicianSearchPCP: '/booking/clinician-first/clinician-search-pcp/',
      clinicianChoicePCP: '/booking/clinician-first/clinician-choice-pcp/',
      clinicianSearchListMidFlow: '/booking/clinician-first/clinician-search-list-mid-flow/',
      schedule: '/booking/clinician-first/schedule/',
    },
    confirmProfile: '/booking/confirm-profile/pc',
    survey: '/profile/survey/',
    payment: {
      pc: '/booking/primary-care/payment',
      fitbit: '/booking/primary-care/payment',
    },
    preventative: {
      annual: {
        checkEligibility: '/booking/preventative/annual-visit/check-eligibility',
        followUp: '/booking/preventative/annual-visit/follow-up',
      },
    },
    register: {
      pc: '/booking/primary-care/register/',
    },
    therapy: {
      howItWorks: '/booking/therapy/how-this-works/',
      selectState: '/booking/therapy/select-state/',
      schedule: '/booking/therapy/schedule/',
      list: '/booking/therapy/list/',
      profile: '/booking/therapy/profile/',
      payment: '/booking/therapy/payment/',
      register: '/booking/therapy/register',
      success: '/booking/therapy/success/',
      triage: (step: number) => `/booking/therapy/triage/${step}/`,
      emergency: '/booking/therapy/triage/emergency/',
      whoIsThisFor: '/booking/therapy/who-is-this-for/',
      createChild: '/booking/therapy/create-child/',
      updateMemberId: '/booking/therapy/update-member-id/',
    },
    specialist: {
      howToSchedule: '/booking/specialist/how-to-schedule',
      specialistRequestForm: '/booking/specialist/appointment-request-form',
      clinicianSearch: '/booking/specialist/specialist-search/',
    },
    selectAddress: '/profile/address/',
    selectPharmacy: '/profile/pharmacy/',
    privacy: '/booking/privacy/',
    addInsurance: '/booking/insurance/add/',
    addInsuranceSuccess: '/booking/insurance/success',
    visitType: '/booking/visit-type/',
    selectPatient: '/booking/primary-care/select-patient/',
    addChild: '/booking/primary-care/add-child/',
    weightManagement: {
      confirmAppointmentType: '/booking/weight-management/confirm-appointment-type',
      expectations: '/booking/weight-management/expectations',
      questionnaire: '/booking/weight-management/questionnaire',
    },
    updateMemberId: '/booking/primary-care/update-member-id/',
  },
  checkIn: {
    intro: '/myhealth/check-in/intro/',
    pharmacy: '/myhealth/check-in/pharmacy/',
    labs: { select: '/myhealth/check-in/labs/select/', confirm: '/myhealth/check-in/labs/confirm/' },
    files: '/myhealth/check-in/files/',
    healthAssessmentStart: '/myhealth/check-in/health-assessment-start/',
    healthAssessmentFinish: '/myhealth/check-in/health-assessment-finish/',
    success: '/myhealth/check-in/success/',
  },
  triage: {
    preStart: '/free-assessment/start/',
    postStart: '/questionnaire/start/',
  },
  redirect: {
    mentalHealth: '/to-mental-health/',
  },
  cancellation: {
    cancel: '/cancel/',
    reasons: '/cancel/reasons/',
    feedback: '/cancel/success/feedback/',
    done: '/cancel/done/',
    specialOffer: '/cancel/special-offer/',
    specialOfferDone: '/cancel/special-offer-done/',
  },
  enterprise: {
    verify: '/enterprise/verify/',
    standaloneVerify: '/enterprise/verify/standalone/',
  },
  registration: {
    base: '/registration/',
    success: '/registration/success/',
    payment: '/registration/payment/',
    enterprise: '/registration/enterprise/',
    enterpriseSuccess: '/registration/enterprise-success/',
    enterpriseFailure: '/registration/enterprise-failure/',
  },
  profile: {
    user: '/profile/edit/user/',
    access: '/profile/edit/access/',
    payment: '/profile/edit/payment/',
    offer: '/profile/edit/membership/',
    additionalOffers: '/profile/edit/additional-offers/',
    children: '/profile/edit/children/',
    pharmacy: '/profile/edit/pharmacy/',
    privacy: '/profile/edit/privacy/',
  },
  password: {
    reset: '/accounts/password/reset/',
    resetConfirm: '/accounts/password/reset/confirm',
    resetDone: '/accounts/password/reset/done/',
  },
  family: {
    invite: '/family/invite',
    faq: '/family/faq',
    success: '/family/success',
  },
  survey: {
    weightLoss: {
      eligibility: '/survey/weight-loss/eligibility',
      hereToHelp: '/survey/weight-loss/hereToHelp',
      result: '/survey/weight-loss/result',
      treatment: '/survey/weight-loss/treatment',
    },
  },
  noom: {
    medication: '/care/noom/medication',
    doctorSwitch: '/care/noom/doctor-switch',
    outcome: '/care/noom/outcome',
    postBooking: '/care/noom/post-booking',
  },
  accoladeX: {
    dashboard: '/my-health',
    visitSummary: '/my-health/appointments/visit-summary',
    prescription: '/my-health/prescriptions/rx',
    receipt: '/my-health/prescriptions/receipt',
    joinVideoCall: '/my-health/appointments/call',
    testConnection: '/my-health/appointments/ready',
  },
  messaging: '/messaging',
} as const;

export const accdXUrl = {
  nextauth: {
    csrf: '/api/auth/csrf',
    logout: '/api/auth/signout',
    session: '/api/auth/session',
  },
};

export const appUrlToName = (url: string) => {
  let appUrlName: any = null;
  switch (url) {
    case appUrl.booking.method:
      appUrlName = 'Primary Care Booking - View - Method Page';
      break;
    default:
      break;
  }
  return appUrlName;
};
