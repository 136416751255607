import { datadogRum } from '@datadog/browser-rum';
import DevLogger from 'src/js/logging/DevLogger';
import { isBrowser } from 'src/js/utils/server';

const DATADOG_EVENTS_INTAKE_URL = 'https://api.datadoghq.com/api/v1/events';

export const ALERT_TYPES = {
  INFO: 'info',
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
  USER_UPDATE: 'user_update',
  RECOMMENDATION: 'recommendation',
  SNAPSHOT: 'snapshot',
} as const;

export const PRIORITIES = {
  LOW: 'low',
  NORMAL: 'normal',
} as const;

export const RUNTIMES = {
  NODE: 'node',
  EDGE: 'edge',
  BROWSER: 'browser',
} as const;

export const TAGS = {
  OPTIMIZELY: 'optimizely',
  PLUSHCARE_API: 'plushcare_api',
  TREECKO: 'treecko',
  MEDICARE: 'medicare',
} as const;

export type Runtime = typeof RUNTIMES[keyof typeof RUNTIMES];

export type AlertType = typeof ALERT_TYPES[keyof typeof ALERT_TYPES];

export type Priority = typeof PRIORITIES[keyof typeof PRIORITIES];

export type LogEventArgs = {
  error?: any;
  text?: string;
  title: string;
  tags?: string[];
  runtime: Runtime;
  aggregationKey?: string;
  alertType?: AlertType;
  dateHappened?: number;
  deviceName?: string;
  host?: string
  priority?: Priority;
  relatedEventId?: number;
  sourceTypeName?: string;
}

// logging function that pushes events to DataDog, mainly used for logging events on the node server and edge runtime
// for logging events in the browser, use the DataDog RUM SDK, unless for a very specific reason that doesn't work
// for your use case
export const logDatadogEvent = ({
  error, text = '', title, runtime, tags = [], aggregationKey, alertType, dateHappened, deviceName, host, priority, relatedEventId,
  sourceTypeName,
}: LogEventArgs) => {
  const API_KEY = process.env.DATADOG_API_KEY;

  const formattedTags = tags ? ['plushcare_web', process.env.NEXT_PUBLIC_ENV, runtime, ...tags] : ['plushcare_web', process.env.NEXT_PUBLIC_ENV, runtime];
  const payload = {
    title,
    text: error ? `${error}${text ? ` | ${text}` : ''}` : text,
    tags: formattedTags,
    aggregation_key: aggregationKey,
    alert_type: alertType,
    date_happened: dateHappened,
    device_name: deviceName,
    host,
    priority,
    related_event_id: relatedEventId,
    source_type_name: sourceTypeName,
  };

  if (runtime !== RUNTIMES.BROWSER && isBrowser()) {
    datadogRum.addError(`logDataDogEvent called in incorrect runtime. Received ${runtime}, but this was called in the browser.`);
    DevLogger.error(`logDataDogEvent called in incorrect runtime. Received ${runtime}, but this was called in the browser.`);
    return;
  }

  if (!API_KEY) {
    if (!isBrowser()) {
      // we can safely use `console.error` on the server since this won't be exposed to the user, and DataDog APM will
      // automatically capture `console.error` calls
      console.error('No DataDog API key found, could not send event to DataDog.', payload);
    } else {
      DevLogger.error('No DataDog API key found, could not send event to DataDog.', payload);
    }
    return;
  }

  if (runtime === RUNTIMES.BROWSER) {
    datadogRum.addError(error, payload);
    DevLogger.error(error, payload);
    return;
  }

  try {
    // eslint-disable-next-line compat/compat
    fetch(DATADOG_EVENTS_INTAKE_URL, {
      headers: {
        'Content-Type': 'application/json',
        'DD-API-KEY': API_KEY,

      },
      method: 'POST',
      body: JSON.stringify(payload),
    });
  } catch (e) {
    console.error(`plushcare_web:${runtime}:[${tags.toString()}]:${title}`, e);
  }
};
