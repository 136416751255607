import * as env from 'utils/env';

const ENV = process.env.NEXT_PUBLIC_ENV;
export const USER_ORIGIN = 'user';
export const THERAPIST = 'therapist';
export const PEDIATRIC_THERAPIST = 'pediatric therapist';
export const STUDY_ID = 'FITBIT-STUDY';
export const COVID_ID = 'FITBIT-COVID';
export const WP_DOMAIN = (ENV === 'prod') ? 'https://plushcare.com' : 'https://wp-staging.plushcare.com';
export const SELECTED_COMPLEX_CONDITION = 'SELECTED_COMPLEX_CONDITION';
export const TPA = 'tpa';
export const HOLD_APT_TIME_COOKIE_NAME = 'hold_time';
export const PRE_BOOKING_QUESTIONNAIRE_UUID = 'c01885b368454640baadc016b3cdb907';
export const POST_BOOKING_QUESTIONNAIRE_UUID = 'd022d07a744f490ca5661060cfd5260b';
export const ATTRIBUTION_QUESTIONNAIRE_UUID = 'dc527221f5c440b5815e38969106456c';

export const STD_ERROR_MSG = 'Oops something went wrong. Please try again later';
export const VIDEO_CALL = 'Video';
export const BOOKING_ERROR = 'There has been an error with your appointment.';
export const SELECT_OTHER_ERROR = 'Please click here to select another appointment';
export const LEAVING_BOOKING_FLOW_ALERT = 'Your appointment will not be held if you leave';

export const RESCHEDULE_REASON = {
  DOCTOR_NO_SHOW: 'doctor no show',
  NULL: '',
} as const;

// consolidation of possible doctor types
export const DOCTOR_TYPE = {
  INTERNAL: 'internal',
  THERAPIST,
  FAMILY: 'family',
  PEDIATRICS: 'pediatrics',
  PEDIATRIC_THERAPIST,
} as const;

// we use this when sending the DoctorType as a query param in the browser to avoid leaking PHI to third-party vendors
// as documented in this ticket https://accoladeinc.atlassian.net/browse/PLUS-9948
export const DEANONYMIZED_DOCTOR_TYPE_MAP = {
  [DOCTOR_TYPE.INTERNAL]: '0',
  [DOCTOR_TYPE.THERAPIST]: '1',
  [DOCTOR_TYPE.FAMILY]: '2',
  [DOCTOR_TYPE.PEDIATRICS]: '3',
  [DOCTOR_TYPE.PEDIATRIC_THERAPIST]: '4',
} as const;

// if we don't explicitly make these strings here, the AnonymizedDoctorType type will be number values instead of
// strings, and eslint will automatically convert the keys back to numbers, we love lint rules
export const ANONYMIZED_DOCTOR_TYPE_MAP = {
  // eslint-disable-next-line quote-props
  '0': DOCTOR_TYPE.INTERNAL,
  // eslint-disable-next-line quote-props
  '1': DOCTOR_TYPE.THERAPIST,
  // eslint-disable-next-line quote-props
  '2': DOCTOR_TYPE.FAMILY,
  // eslint-disable-next-line quote-props
  '3': DOCTOR_TYPE.PEDIATRICS,
  // eslint-disable-next-line quote-props
  '4': DOCTOR_TYPE.PEDIATRIC_THERAPIST,
} as const;

export type DoctorType = (typeof DOCTOR_TYPE)[keyof (typeof DOCTOR_TYPE)];

export type AnonymizedDoctorType = typeof DEANONYMIZED_DOCTOR_TYPE_MAP[keyof typeof DEANONYMIZED_DOCTOR_TYPE_MAP];

export const doctorType = (doctorTypeStr: string): DoctorType => {
  if (Object.values(DOCTOR_TYPE).includes(doctorTypeStr as DoctorType)) {
    return doctorTypeStr as DoctorType;
  }
  return DOCTOR_TYPE.INTERNAL; // fallbacks to internal
};

export const BOOKING_FLOW = {
  THERAPY: 'therapy',
  PC: 'pc',
  PREVENTATIVE_AWV: 'preventative_annual_wellness_visit',
} as const;
export type BookingFlow = (typeof BOOKING_FLOW)[keyof (typeof BOOKING_FLOW)];

export const ENTERPRISE_USER_STATUS = {
  NONE: 'none',
  PARTIAL: 'partial',
  FULL: 'full',
} as const;
export type EnterpriseUserStatus = (typeof ENTERPRISE_USER_STATUS)[keyof (typeof ENTERPRISE_USER_STATUS)];

export const ROUTER_TYPE = {
  REACT: 'react',
  NEXT: 'next',
} as const;
export type RouterType = (typeof ROUTER_TYPE)[keyof (typeof ROUTER_TYPE)];

export const PAYMENT_METHOD = {
  NO_CHOICE: 'no_choice',
  WITH_INSURANCE: 'with_insurance',
  OUT_OF_POCKET: 'out_of_pocket',
} as const;

export const CANCELATION_WINDOW_THRESHOLD = 5;

export const GENDER_IDENTITY = {
  MALE: 'Male',
  FEMALE: 'Female',
  TRANS_MALE: 'Trans male',
  TRANS_FEMALE: 'Trans female',
  INTER_SEX: 'Intersex',
  SELF_IDENTIFIED: 'Self-Identified',
} as const;
export type GenderIdentity = keyof typeof GENDER_IDENTITY
export const GenderOptions = Object.entries(GENDER_IDENTITY).map(([key, value]) => ({ label: value, value: key }));

export const RACE = {
  BLACK: 'African american/Black',
  ASIAN: 'Asian',
  CAUCASIAN: 'Caucasian/White',
  INDIGENOUS: 'Native american/Alaskan native/Inuit',
  ISLANDER: 'Pacific islander',
  OTHER: 'Other',
  UNDEFINED: 'Prefer not to say',
} as const;
export type Race = keyof typeof RACE
export const RaceOptions = Object.entries(RACE).map(([key, value]) => ({ label: value, value: key }));

export const ETHNICITY = {
  HISPANIC: 'Hispanic/Latino/Latina',
  NOT_HISPANIC: 'Not Hispanic/Latino/Latina',
} as const;
export type Ethnicity = keyof typeof ETHNICITY
export const EthnicityOptions = Object.entries(ETHNICITY).map(([key, value]) => ({ label: value, value: key }));

export const SEXUAL_ORIENTATION = {
  HETEROSEXUAL: 'Straight or heterosexual',
  HOMOSEXUAL: 'Lesbian, gay, or homosexual',
  PANSEXUAL: 'Bisexual or pansexual',
  OTHER: 'Other',
  UNDEFINED: 'Prefer not to say',
  UNDECIDED: 'Undecided',
} as const;
export type SexualOrientation = keyof typeof SEXUAL_ORIENTATION
export const SexualOrientationOptions = Object.entries(SEXUAL_ORIENTATION).map(
  ([key, value]) => ({ label: value, value: key }),
);

export const PRONOUNS = {
  SHE: 'She/her/hers',
  HE: 'He/him/his',
  THEY: 'They/them/theirs',
  OTHER: 'Other',
} as const;
export type Pronouns = keyof typeof PRONOUNS
export const PronounOptions = Object.entries(PRONOUNS).map(
  ([key, value]) => ({ label: value, value: key }),
);

export const FLOW_TYPES = {
  PREVENTATIVE: 'preventative',
} as const;

export const FLOW_SUB_TYPES = {
  ANNUAL_VISIT: 'annual_visit',
} as const;

// Why is there optional chaining here? Because for some reason this was breaking specific tests where the ENVIRONMENTS
// variable was undefined. Most likely a bundler issue with Jest, but this fixes it for now.
// We also can't import the ENVIRONMENTS variable and use it directly for the same reason. I love JavaScript.
export const NODE_API_URL = env.is(env.ENVIRONMENTS?.PROD)
  ? 'http://plushcare.com/api/'
  : env.is(env.ENVIRONMENTS?.STAGE)
    ? 'http://my.stage.plushcare.com/api/'
    : env.is(env.ENVIRONMENTS?.DEV)
      ? 'http://my.int.dev.plushcare.com/api/'
      : 'http://127.0.0.1:3000/api/';
