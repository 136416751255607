import React, { ReactComponentElement } from 'react';
import { CheckboxStyled } from '../forms/Inputs/Checkbox/Checkbox.styled';

interface props {
  id: string,
  label: ReactComponentElement<any>,
  onChange: any,
  checked: boolean,
}
const TosCheckbox = (props: props) => {
  const {
    id, label, onChange, checked,
  } = props;
  const handleChange = () => { onChange(); };
  // eslint-disable-next-line react/destructuring-assignment
  return (
    <CheckboxStyled>
      <input
        name={id}
        type="checkbox"
        id={id}
        onChange={handleChange}
        checked={checked}
        aria-labelledby={`${id}-label`}
        title="Terms of Service Opt-in"
      />
      <label htmlFor={id} id={`${id}-label`}>
        {label}
      </label>
    </CheckboxStyled>
  );
};

export default TosCheckbox;
