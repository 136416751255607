import { UpcomingAppointment } from 'src/js/globalTypes';
import { ActionableTask, ActionableTaskNamespace } from 'src/js/reducers/DashboardReducer';
import Analytics from 'src/js/utils/analytics/Analytics';

export const parseFollowupAppointment = (tasks: Array<ActionableTask>): Array<UpcomingAppointment> => {
  return tasks
    .filter(({ namespace }) => Object.values(ActionableTaskNamespace).includes(namespace))
    .map(({
      payload: {
        doctor, recommended_appointment, doctor_active, card_message,
      },
      payload,
      id: taskId,
    }) => {
      try {
        return {
          taskId,
          payload,
          doctor,
          recommended_appointment,
          id: recommended_appointment ? recommended_appointment.id : -1,
          appointment_utc_time: recommended_appointment ? recommended_appointment.appointment_utc_time : '',
          appointment_status: recommended_appointment ? recommended_appointment.appointment_status : '',
          appointment_id: recommended_appointment ? recommended_appointment.appointment_id : '',
          reason: '',
          doctor_active,
          doctor_type: doctor?.doctor_type || '',
          card_message,
        };
      } catch (error) {
        error.message = `Error parseFollowupAppointment for actionable task ID ${taskId}: ${error.message}`;
        Analytics.otherException(error);
        return null;
      }
    })
    .filter(task => task !== null);
};
