import React from 'react';

import { apiUrl } from 'src/js/utils';
import { V2Success } from 'src/js/utils/apiV2';
import { getFetcher } from 'src/js/endpoints/fetchers';
import { useSSRSWR } from 'src/js/endpoints/ServerSideSWR';

type CommonAnalyticsAttributes = {
  employee?: {
    brand: string,
    customer: string,
    subgroup: string,
  },
  personId: string,
}

const useCommonAnalyticsAttributes = () => {
  const {
    data,
    mutate,
  } = useSSRSWR<V2Success<CommonAnalyticsAttributes>>(apiUrl.analytics.commonAttributes, getFetcher);

  return React.useMemo(
    () => ({
      attributes: data?.payload,
      reloadAttributes: mutate,
    }),
    [
      data,
      mutate,
    ],
  );
};

export default useCommonAnalyticsAttributes;
