// we define statically only possible values so result cannot be undefined
export const current = process.env.NEXT_PUBLIC_ENV;
export const ENVIRONMENTS = {
  TEST: 'test',
  LOCAL: 'local',
  DEV: 'dev',
  STAGE: 'stage',
  PROD: 'prod',
} as const;

export const is = (env: Env) => env === current;

export const sentry = {
  prodDns: 'https://49e4969686c240c2875ab90fd27938d6@o227741.ingest.sentry.io/5652506',
};
