import { colors } from 'src/js/nextgen/plushcare-components/helpers/plushcare-style-defaults';
import { AppTheme } from 'src/js/nextgen/themes/base';

export const PlushCareTheme: AppTheme = {
  __name__: 'PlushCareTheme',

  colors: {
    background: colors.white,
    foreground: colors.midnight,
    gray1: colors.wildSand,
    gray2: colors.backgroundGray,
    gray3: colors.gainsboro,
    gray4: colors.chineseSilver,
    gray5: colors.philippineSilver,
    gray6: colors.gray,
    gray7: colors.legalGrey,
    gray8: colors.outerSpace,
    primary: colors.midnight,
    primaryHover: colors.cloud,
    primaryBackground: colors.cloud,
    warning: colors.goldenYellow,
  },
};
