import {
  SET_SOURCE,
} from '../actionTypes/SourceActionType';

import {
  STUDY_ID,
  COVID_ID,
} from '../nextgen/utils/constants';

const initialState = {
  source_id: null,
  external_id: null,
  isFitbitStudy: false,
};

function SourceReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SOURCE:
      return {
        ...state,
        ...action.payload,
        isFitbitStudy: action.payload ? action.payload.source_id === STUDY_ID : false,
        isCovid: action.payload ? action.payload.source_id == COVID_ID : false,
      };
    default:
      return state;
  }
}

export default SourceReducer;
