import React from 'react';
import { useAppVariant, AppVariants } from 'utils/hooks/useAppVariant';
import { useIsTherapyFlow } from 'src/js/components/bookingFlow/Therapy/helpers';

export const PrivacyPolicyLink = () => {
  const { variant } = useAppVariant();
  const path = variant === AppVariants.AccoladeCare ? 'privacy-policy/' : 'privacy/';
  return (
    <a
      href={`https://${variant}.com/${path}`}
      target="_blank"
      rel="noreferrer"
    >
      Privacy Policy
    </a>
  );
};

export const TermsOfUseLink = ({ children }) => {
  const { variant } = useAppVariant();
  const isTherapyFlow = useIsTherapyFlow();
  // not including trailing slashes here because we add them below in the href string interpolation
  const path = variant === AppVariants.AccoladeCare ? 'terms-of-use' : 'terms';
  return (
    <a
      href={`https://${variant}.com/${path}/${isTherapyFlow ? '#:~:text=.-,8.,-Permission%20' : ''}`}
      target="_blank"
      rel="noreferrer"
    >
      {children || 'Terms of Use'}
    </a>
  );
};

export const TelehealhConsentPolicyLink = () => {
  const { variant } = useAppVariant();
  return (
    <a
      href={`https://${variant}.com/terms/#telehealth`}
      target="_blank"
      rel="noreferrer"
    >
      Telehealth Consent Policy
    </a>
  );
};
