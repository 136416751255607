import styled from '@emotion/styled';
import { colors } from '../../../plushcare-components';

export const CheckboxStyled = styled.div`
  margin: 24px 0 0 0;

  input[type=checkbox] {
    position: absolute;
    opacity: 0;

    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
      line-height: 16px;
    }

    & + label:before {
      content: '';
      margin-right: 12px;
      display: inline-block;
      vertical-align: middle;
      min-width: 18px;
      height: 18px;
      border: 1px solid ${props => props.theme.colors.foreground};
      border-radius: 2px;
    }

    &:checked + label:before {
      background: ${props => props.theme.colors.foreground};
    }

    &:checked + label:after {
      content: '';
      position: absolute;
      left: 3px;
      top: 9px;
      background: ${props => props.theme.colors.foreground};
      width: 2px;
      height: 2px;
      box-shadow:
        2px 0 0 ${colors.white},
        4px 0 0 ${colors.white},
        4px -2px 0 ${colors.white},
        4px -4px 0 ${colors.white},
        4px -6px 0 ${colors.white},
        4px -8px 0 ${colors.white};
      transform: rotate(45deg);
    }
  }

  label {
    // margin: 0 0 0 10px;
    font-size: 16px;
    color: ${props => props.theme.colors.foreground};
  }
`;
