import React from 'react';
import styled from '@emotion/styled';

const HamburgerStyled = styled.li`

display: block;
margin-left: auto;
position: relative;
z-index: 10;
transition: ease 0.4s;
font-size: 0;

::before {
  top: -5px;
}

::after {
  top: 5px;
}

span {
  display: inline-block;
  height: ${props => (props.height)};
  width: ${props => (props.width)};
  border-radius: 35px;
  content: "";
  background-color: ${props => props.theme.colors.foreground};
}

::after,
::before {
  position: absolute;
  height: ${props => (props.height)};
  width: ${props => (props.width)};
  background-color: ${props => props.theme.colors.foreground};
  border-radius: 35px;
  content: "";
  left: 0;
}

`;

const HamburgerButton = (props) => (
  <HamburgerStyled id="nav-hambuger" {...props}>
    <span />
  </HamburgerStyled>
);

HamburgerButton.defaultProps = {
  height: '2px',
  width: '20px',
};

export default HamburgerButton;
