import styled from '@emotion/styled';
import { colors } from '../helpers/plushcare-style-defaults';

export const TotalBar = styled.div`
  position: relative;
  top: -15px;
  width: 100%;
  height: 15px;
  border-radius: 7px;
  background: ${colors.white};
`;

export const PercentageBar = styled.div`
  position: relative;
  height: 15px;
  border-radius: 7px;
  width: ${props => props.percentage};
  background: ${colors.royalBlue};
  z-index: 1;
`;
