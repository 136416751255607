import { AppTheme } from 'src/js/nextgen/themes/base';
import { Variant } from 'utils/hooks/useAppVariant';

import { AccoladeCareTheme } from 'src/js/nextgen/themes/accoladecare';
import { BscTheme } from 'src/js/nextgen/themes/bsc';
import { PlushCareTheme } from 'src/js/nextgen/themes/plushcare';

export { AccoladeCareTheme } from 'src/js/nextgen/themes/accoladecare';
export { BscTheme } from 'src/js/nextgen/themes/bsc';
export { PlushCareTheme } from 'src/js/nextgen/themes/plushcare';

export const themes: Record<Variant, AppTheme> = {
  accoladecare: AccoladeCareTheme,
  celeste: AccoladeCareTheme,
  bsc: BscTheme,
  plushcare: PlushCareTheme,
};
