import {
  RUNTIMES, TAGS, logDatadogEvent, ALERT_TYPES,
} from 'src/js/logging/datadog';
import { NODE_API_URL } from 'src/js/nextgen/utils/constants';
import { IncomingMessage, ServerResponse } from 'http';

// generates the anonymous id if the cookie isn't set, sets the new header value on the response, and returns the value
// commiting crimes against nature and returning a value from a side effect, forgive me father for I have sinned
// this is a serverside only function
export const assignAnonymousId = ({ req, res }: { req: IncomingMessage, res: ServerResponse }): string | null => {
  const currentCookies = req.headers.cookie;
  let anonymousId = null;
  // if the user is using an ad blocker, Segment can't set the anonymous user id cookie, so we have to do it ourselves
  // to ensure that we can pass the correct anonymous user id to Optimizely for evaluating feature flags
  // we can't do this in a middleware either as if we want feature flags to be evaluated on the server, we need to pass
  // the anonymous user id to the <ABTestProvider> component, which we have to do from _app.js
  if (!currentCookies || !currentCookies.includes('ajs_anonymous_id')) {
    anonymousId = crypto.randomUUID();
    const ajsCookie = `ajs_anonymous_id=${anonymousId}; domain=plushcare.com; path=/; max-age=${60 * 60 * 24 * 365}; sameSite=lax; secure;`;
    res.setHeader('Set-Cookie', ajsCookie);
  }
  return anonymousId;
};

export const fetchDatafileFromNodeAPI = async (): Promise<string | null> => {
  let optimizelyDatafile = null;

  let response;
  try {
    // eslint-disable-next-line compat/compat
    response = await fetch(`${NODE_API_URL}optimizely-datafile/`);
  } catch (e) {
    logDatadogEvent({
      title: 'Could not fetch local or remote Optimizely datafile',
      alertType: ALERT_TYPES.ERROR,
      error: e,
      runtime: RUNTIMES.NODE,
      tags: [TAGS.OPTIMIZELY],
    });
    return optimizelyDatafile;
  }

  if (response.status !== 200) {
    return optimizelyDatafile;
  }

  try {
    const data = await response.text();
    optimizelyDatafile = data;
  } catch (e) {
    logDatadogEvent({
      title: 'Could not parse optimizely datafile',
      alertType: ALERT_TYPES.ERROR,
      error: e,
      runtime: RUNTIMES.NODE,
      tags: [TAGS.OPTIMIZELY],
    });
  }
  return optimizelyDatafile;
};
