import { LocalStorage } from './storage';

export const ssoRedirectUrlCache = {
  key: 'sso-redirect-url',
  load(): string {
    return LocalStorage.getItem(ssoRedirectUrlCache.key);
  },
  save(redirectUrl: string) {
    LocalStorage.setItem(ssoRedirectUrlCache.key, redirectUrl);
  },
  clear() {
    LocalStorage.removeItem(ssoRedirectUrlCache.key);
  },
  pop() {
    const redirectUrl = ssoRedirectUrlCache.load();
    if (redirectUrl) {
      ssoRedirectUrlCache.clear();
    }
    return redirectUrl;
  },
};
