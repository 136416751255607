import {
  SET_FOLLOWUP_APPT,
  SET_RESCHEDULE_APPT,
} from '../actionTypes/BookingActionType';

const initialState = {
  appointment: null,
  isReschedule: false,
  isFollowup: false,
};

function RescheduleReducer(state = initialState, action) {
  switch (action.type) {
    case SET_RESCHEDULE_APPT:
      return {
        appointment: action.value,
        isReschedule: !!(action.value && action.value.id),
        isFollowup: false,
      };
    case SET_FOLLOWUP_APPT:
      return {
        appointment: action.value,
        isReschedule: false,
        isFollowup: true,
      };
    default:
      return state;
  }
}

export default RescheduleReducer;
