import React, { PropsWithChildren, useEffect } from 'react';
import Head from 'next/head';
import { datadogRum } from '@datadog/browser-rum';

import * as env from 'utils/env';
import { AppVariants, useAppVariant } from 'utils/hooks/useAppVariant';
import { getTimezoneName } from 'src/js/utils';
import { isServer } from 'src/js/utils/server';
import * as cookieState from 'utils/cookieState';
import favIconPlushCare from 'public/img/favicon.png';
import favIconAccoladeCare from 'public/img/accolade/favicon.png';

const initTracking = () => {
  if (env.is('prod')) {
    // we need to disable this method on the SDK so that it never accidentally gets turned on for compliance reasons
    // @ts-ignore
    datadogRum.startSessionReplayRecording = null;

    datadogRum.init({
      applicationId: '2712408e-c3ee-4925-bc09-381726a9fa01',
      clientToken: 'pubff81238fecf70a925c9299bd6a488780',
      site: 'datadoghq.com',
      service: 'web2.0',
      env: env.current,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,
      // session replays should never get enabled, but this ensures that any screen information (text, images, input)
      // is censored in the event that somehow a session is recorded
      defaultPrivacyLevel: 'mask',
      version: process.env.SOURCE_VERSION,
      trackUserInteractions: true,
      allowedTracingUrls: ['https://www.api.plushcare.com', /https:\/\/.*\.plushcare\.com/],
      enableExperimentalFeatures: ['feature_flags'],
      beforeSend: (event) => {
        const confirmResetPasswordURL = '/accounts/password/reset/confirm/';
        if (event.view.url.includes(confirmResetPasswordURL)) {
          // eslint-disable-next-line no-param-reassign
          event.view.url = confirmResetPasswordURL;
        }

        if (event.view.referrer?.includes(confirmResetPasswordURL)) {
          // eslint-disable-next-line no-param-reassign
          event.view.referrer = confirmResetPasswordURL;
        }
      },
    });
  }
};

if (!isServer) {
  initTracking();
}

const Page = ({ children }: PropsWithChildren<{}>) => {
  const { variant, displayName } = useAppVariant();
  const favicon = variant === AppVariants.AccoladeCare
    ? favIconAccoladeCare
    : favIconPlushCare;

  useEffect(() => {
    if (!cookieState.get('user_timezone')) {
      cookieState.set('user_timezone', getTimezoneName());
    }
  }, []);
  return (
    <>
      <Head>
        <title>
          Talk to a doctor on your phone |
          {' '}
          {displayName}
        </title>
        <link rel="shortcut icon" type="image/ico" href={favicon} />
        <link
          rel="preload"
          href="/fonts/TTCommons-Regular.woff"
          as="font"
          crossOrigin=""
        />
      </Head>
      {children}
    </>
  );
};

export default Page;
