import React from 'react';
import { useAppVariant } from 'utils/hooks/useAppVariant';
import {
  PrivacyPolicyLink,
  TermsOfUseLink,
  TelehealhConsentPolicyLink,
} from './PlushCareLinks';

const TermsOfService = ({
  actionText = 'clicking "Continue"',
}) => {
  const { displayName } = useAppVariant();
  return (
    <p className="tos-text">
      By&nbsp;
      { actionText }
      , you are agreeing to the&nbsp;
      {displayName}
      &nbsp;
      <TermsOfUseLink />
      ,&nbsp;
      <PrivacyPolicyLink />
      &nbsp; &amp; &nbsp;
      <TelehealhConsentPolicyLink />
      .
    </p>
  );
};

export default TermsOfService;
