import throttle from 'lodash/throttle';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { composeWithDevTools } from '@redux-devtools/extension';
import { createWrapper } from 'next-redux-wrapper';
import * as cookieState from 'utils/cookieState';
import * as env from 'utils/env';
import { ENVIRONMENTS } from 'utils/env';
import BookingReducer from 'src/js/reducers/BookingReducer';
import ChildReducer from 'src/js/components/Child/ChildReducer';
import DashboardReducer, { StoreState as DashboardState } from 'src/js/reducers/DashboardReducer';
import FloatingInsuranceReducer from 'src/js/reducers/InsuranceReducer';
import LocationReducer, { LocationState } from 'src/js/reducers/LocationReducer';
import { reducer as PaymentReducer, initialState as paymentInitialState } from 'src/js/store/payment';
import PharmacyReducer, { PharmacyState } from 'src/js/reducers/PharmacyReducer';
import RescheduleReducer from 'src/js/reducers/RescheduleReducer';
import SourceReducer from 'src/js/reducers/SourceReducer';
import UserReducer, { UserState } from 'src/js/reducers/UserReducer';
import { USER_FULL_LOGOUT } from 'src/js/actionTypes/UserActionType';
import { loadStore, saveStore } from 'src/js/utils/storage';

export const storageNamespace = 'global-store';

const app = combineReducers({
  booking: BookingReducer,
  child: ChildReducer,
  dashboard: DashboardReducer,
  insurance: FloatingInsuranceReducer,
  location: LocationReducer,
  payment: PaymentReducer,
  pharmacy: PharmacyReducer,
  reschedule: RescheduleReducer,
  source: SourceReducer,
  user: UserReducer,
});

type AppInferred = ReturnType<typeof app>

export interface RootStore {
  booking?: AppInferred['booking']
  child?: AppInferred['child'],
  dashboard?: DashboardState,
  insurance?: AppInferred['insurance'],
  location?: LocationState,
  payment: AppInferred['payment'],
  pharmacy?: PharmacyState,
  reschedule?: AppInferred['reschedule'],
  source?: AppInferred['source'],
  user?: UserState,
}

export const rootReducer = (state: RootStore, action: { type: typeof USER_FULL_LOGOUT }) => {
  let updatedState = { ...state };

  if (action.type === USER_FULL_LOGOUT) {
    // TODO - this is reducer we cannot have side effects here
    // move side effects from here
    window.sessionStorage.clear();
    cookieState.clear('payer');

    updatedState = {
      user: undefined,
      child: undefined,
      dashboard: undefined,
      insurance: undefined,
      pharmacy: undefined,
      payment: paymentInitialState,
      source: undefined,
      reschedule: undefined,
      location: undefined,
      booking: undefined,
    };
  }

  // @ts-ignore
  return app(updatedState, action);
};

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
});

const middleware = applyMiddleware(promise, thunk);

const middlewareWithDevTools = composeEnhancers(middleware);

//  @TODO: we're getting an error here because we're using a deprecated version of createStore
// it has multiple overloads and we're using a deprecated one by calling it this way
export const store = (env.is(ENVIRONMENTS.PROD) || env.is(ENVIRONMENTS.STAGE))
  // @ts-expect-error getting a type error from the deprectated overload
  ? createStore(rootReducer, loadStore(storageNamespace), middleware)
  // @ts-expect-error getting a type error from the deprectated overload
  : createStore(rootReducer, loadStore(storageNamespace), middlewareWithDevTools);
store.subscribe(throttle(() => { saveStore(store.getState(), storageNamespace); }, 100));

export const wrapper = createWrapper(() => store);
