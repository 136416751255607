import useSWR, { Key, useSWRConfig, SWRConfiguration } from 'swr';

type FetcherFunctionType<Data, Error> =
  | ((...args: any) => Data | Promise<Data | Error>)
  | null;

export function useSSRSWR<Data = any, Error = any>(
  key: Key,
  fetcher: FetcherFunctionType<Data, Error>,
  config?: SWRConfiguration<Data, Error> & {initialData?: Data},
  forceRevalidate = false,
) {
  const initialData = config?.initialData;
  const { cache } = useSWRConfig();
  if (initialData) {
    cache.set(key, initialData);
  }

  // @ts-ignore because `useSWR's` definition of `fetcher` allows only Data to be returned;
  return useSWR<Data, Error>(key, fetcher, {
    errorRetryCount: 3,
    revalidateOnFocus: forceRevalidate || false,
    // this is required for pages under react-router. They don't pass initial data => we need to revalidate on mount,
    // but user may come from Next page and SWR may already have cached data => we don't revalidate on mount
    revalidateOnMount: forceRevalidate || !cache.get(key),
    ...config,
  });
}
