export const SET_HOME_ADDRESS = 'SET_HOME_ADDRESS';
export const SET_PHARMACY_ADDRESS = 'SET_PHARMACY_ADDRESS';
export const SET_APPOINTMENT_DATA = 'SET_APPOINTMENT_DATA';
export const SET_PRE_BOOKED_APPOINTMENT_DATA = 'SET_PRE_BOOKED_APPOINTMENT_DATA';
export const SET_BOOKED_APPOINTMENT_DETAILS = 'SET_BOOKED_APPOINTMENT_DETAILS';
export const SET_PHARMACY_LIST = 'SET_PHARMACY_LIST';
export const SET_SPECIALTY = 'SET_SPECIALTY';
export const SET_POST_SUCCESS = 'SET_POST_SUCCESS';
export const SET_LOCATION_COORDINATES = 'SET_LOCATION_COORDINATES';
export const SET_LOADING = 'SET_LOADING';
export const SET_LIST_LOADING = 'SET_LIST_LOADING';
export const SET_ERROR = 'SET_ERROR';
export const SET_CLEAR_PHARMACY_STORE = 'SET_CLEAR_PHARMACY_STORE';
export const SET_RESCHEDULE_APPT = 'SET_RESCHEDULE_APPT';
export const SET_FOLLOWUP_APPT = 'SET_FOLLOWUP_APPT';
export const SET_BOOKING_DOCTOR = 'SET_BOOKING_DOCTOR';
export const SET_BOOKING_CLINICIAN = 'SET_BOOKING_CLINICIAN';
export const SET_BOOKING_FILTER_DATA = 'SET_BOOKING_FILTER_DATA';
export const SET_INITIAL_BOOKING_DATA_LOADED = 'SET_INITIAL_BOOKING_DATA_LOADED';
export const SET_FLOW_TYPE = 'SET_FLOW_TYPE';
