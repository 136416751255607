import { useMemo } from 'react';

const useBaseUrl = (link?: string, baseUrl: string = process.env.NEXT_PUBLIC_APP_ZONE) => {
  return useMemo(
    () => {
      if (!link) return link;

      if (link.startsWith('/') && baseUrl) {
        return `${baseUrl.replace(/\/$/, '')}/${link.replace(/^\//, '')}`;
      }
      return link;
    },
    [link, baseUrl],
  );
};

export default useBaseUrl;
