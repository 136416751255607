import styled from '@emotion/styled';

export const colors = {
  cloud: '#b2dded',
  midnight: '#283779',
  royalBlue: '#0084ff',
  adaRoyalBlue: '#0075E4',
  darkBlue: '#1F346B',
  darkerBlue: '#0075E4',
  adaRed: '#dc3545',
  sunshine: '#ffb74c',
  violet: '#bf3fa9',
  ocean: '#33b4d2',
  beach: '#f5dace',
  rain: '#e9eef2',
  white: '#fff',
  charcoal: '#212529',
  green: '#2FB500',
  black: '#000',
  platinum: '#b3bbbd',
  red: '#E1595E',
  legalGrey: '#828282',
  gray: '#979DA4',
  adaGray: '#63798F',
  backgroundGray: '#6c757d',
  darkGray: '#4f565e',
  borderGray: '#cdd3da',
  lightBackgroundGray: '#e6eaee',
  lightBackgroundMidnight: 'rgba(40,55,121,0.3)',
  placeholderGray: '#C3CBCE',
  darkParagrahGray: '#0C0E0F',
  errorText: '#DE343A',
  errorBackground: 'rgba(222,52,58,0.1)',
  plushcareGrey: '#B3B8BD',
  malachite: '#06C124',
  plushcareDarkGrey: '#6E8195',
  backgroundGrey: '#E4EAF0',
  checklistBorder: '#B8C4D1',
  disabledBoldGrey: '#8C939B',
  successMsgBg: '#e5fff2',
  successDarkGreen: '#198754',
  hoverStateGrey: '#F0F4F5',
  support: '#4F4F4F',
  bodyText: '#111111',
  therapyBenefitsModalTitle: '#021424',
  trueRed: '#ff0000',
  chineseSilver: '#CCCCCC',
  athensGray: '#F0F2F5',
  azure: '#0085FF',
  grayCyan: '#E0F1F8',
  cyan: '#C1E4F1',
  gainsboro: '#DDDDDD',
  cultured: '#F7F7F7',
  blueCola: '#0077E6',
  outerSpace: '#444444',
  spanishGray: '#999999',
  maxBlueGreen: '#32c7b3',
  lightSilver: '#d8d8d8',
  cinnabar: '#d14e2e',
  topaz: '#ffc570',
  philippineSilver: '#b4b3b3',
  chineseWhite: '#e0e0e0',
  crayola: '#A9AFC9',
  sonic: '#757575',
  alice: '#EEEEFF',
  brightGray: '#e4eaef',
  davy: '#545960',
  silverLake: '#5E89C1',
  hawk: '#D8DDE3',
  blueJeans: '#65aaff',
  greywacke: '#BEBBBB',
  americanYellow: '#F7B500',
  goldenYellow: '#F0932B',
  taupe: '#878889',
  mercury: '#e4e6e7',
  mako: '#454c53',
  buttonBlue: '#2196f3',
  richGold: '#A55327',
  foil: '#aaafb5',
  carrara: '#e9eced',
  blueberry: '#4285f4',
  lotion: '#FAFAFA',
  lemon: '#fffaca',
  raven: '#71767c',
  manatee: '#949ca2',
  wildSand: '#f4f4f4',
  nyanza: '#e6f4e0',
  grass: '#278300',
  nocturnal: '#464646',
  condor: '#5d5d5d',
  deepRed: '#8b0000',
  sandstorm: '#f6d442',
  webGrey: '#818181',
  police: '#304A68',
  zomp: '#44AC95',
  terraCota: '#CE5358',
  forest: '#268D2D',
  greenBlue: '#025DB8',
  autoMetalSaurus: '#63768a',
  firebrick: '#b61f24',
  darkGreyX11: '#a9a9a9',
  floral: '#fffcee',
  americanSilver: '#cfcfcf',
  auburn: '#a2262a',
  tinpan: '#5a5a5a',
  americanGreen: '#28a745',
  pine: '#61b495',
  malibu: '#62C1E5',
  han: '#456CC0',
  lightWarning: '#FFF0CC',
  chestnut: '#A55327',
  diamondLeague: '#D1EBF4',
  powderBlue: '#ADE1ED',
  // colors below are needed while we are still using emotion as our styled import
  ads: {
    default: '#097167',
    hover: '#279E92',
    selected: '#14524C',
    background: '#D6F5F2',
  },
  teal: '#33B4D2',
  blueDark: '#111D51',
  softBlack: '#1A1A1A',
};

// TODO: this set of breakpoints doesn't match with grid systems' set
export const breakpoints = {
  xsmMobile: '320px',
  smMobile: '400px',
  mobile: '600px',
  tablet: '768px',
  desktop: '1024px',
  lgDesktop: '1500px',
};

export const headerSizes = {
  mobile: {
    height: 52,
  },
  desktop: {
    height: 55,
  },
};

export const FlexWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 15px;
  @media (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
  }
  @media (min-width: ${breakpoints.desktop}) {
    padding: 0 35px;
  }
`;
