import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import Link from 'next/link';
import { colors } from './helpers/plushcare-style-defaults';

const PageErrorStyled = styled.div<{ hasError: boolean }>`
  background-color: ${colors.adaRed};
  color: white;
  font-size: 15px;
  line-height: 1rem;
  opacity: ${props => (props.hasError ? 1 : 0)};
  padding: 1rem;
  text-align: center;
  transition: opacity 1s ease-out;
  width: 100%;

  a {
    color: inherit;
    text-decoration: underline;
  }
`;

type Props = {
  text: string
  redirectLink?: string
  redirectText?: string
}

const PageError = forwardRef<HTMLDivElement, Props>(({ text, redirectLink, redirectText }: Props, ref) => {
  return (
    <PageErrorStyled data-testid="pageError" hasError={text?.length > 0} ref={ref}>
      {`${text}`}
      {redirectLink && redirectText && (
        <>
          {' '}
          <Link href={redirectLink}>
            {redirectText}
          </Link>
        </>
      )}
    </PageErrorStyled>
  );
});

export default PageError;
