import React from 'react';
import styled from '@emotion/styled';
import { ButtonType } from '@accolade/design-system-react';

import { Button, PlushButtonTypes } from 'src/js/nextgen/ads-components/Button';
import { AppVariants, useAppVariant } from 'utils/hooks/useAppVariant';

type Direction = 'horizontal' | 'vertical';
type Order = 'normal' | 'reverse';
type Tag = 'a' | 'button';

type StyledButtonPairProps = {
  direction?: Direction,
  height?: string,
  order?: Order;
  width?: string,
  gap?: string,
}

export const StyledButtonPair = styled.div<StyledButtonPairProps>`
  display: grid;
  grid-template-columns: ${props => (props.direction === 'horizontal' ? '1fr 1fr' : 'none')};
  grid-gap: ${props => (props.gap || '20px')};
  width: ${props => (props.width === 'auto' ? 'auto' : props.width)};
  text-align: center;

  .primary-pair-button {
    &:last-child {
      order: ${props => (props.order === 'reverse' ? '-1' : '1')};
    }
  }

  .primary-pair-button.pc-button-pair {
    font-weight: 600;

    &:last-child {
      order: ${props => (props.order === 'reverse' ? '-1' : '1')};
    }
  }

  .primary-pair-link.pc-button-pair {
    font-weight: 600;
    font-size: 15px;
    color: ${props => props.theme.colors.foreground};
    height: ${props => (props.height || 'auto')};
  }

  .acc-button-pair:hover {
    text-decoration: none;
  }
`;

interface ButtonPairItemProps {
  type: PlushButtonTypes | 'anchor',
  tag?: Tag,
  link?: string,
  onClick?: (() => void),
  text?: string,
  fontSize?: string,
  isDisabled?: boolean,
  noLoader?: boolean,
  height?: string,
  adsButtonType?: ButtonType,
  overflow: string,
}
// TODO: consider a hash for button/anchor components...
const ButtonPairItem = ({
  type,
  tag,
  link,
  onClick,
  text,
  fontSize,
  isDisabled,
  noLoader,
  height,
  adsButtonType,
  overflow,
}: ButtonPairItemProps) => {
  const { variant } = useAppVariant();
  const buttonClass = variant === AppVariants.AccoladeCare
    ? 'acc-button-pair'
    : 'pc-button-pair';
  return (type === 'anchor'
    ? (
      <a
        href={link}
        onClick={onClick}
        className={`primary-pair-link ${buttonClass}`}
      >
        {text}
      </a>
    ) : (
      <Button
        tag={tag}
        type={type}
        className={`primary-pair-button ${buttonClass}`}
        link={link}
        onClick={onClick}
        text={text}
        fontSize={fontSize}
        isDisabled={isDisabled}
        noLoader={noLoader}
        height={height}
        buttonType={adsButtonType}
        style={{ overflow }}
      />
    ));
};

interface Props {
  direction?: Direction,
  order?: Order,
  width?: string,
  gap?: string,
  primaryButtonHidden?: boolean,
  primaryType?: PlushButtonTypes | 'anchor',
  primaryTag?: Tag,
  primaryLink?: string,
  primaryClick?: (() => void),
  primaryText?: string,
  primaryTextSize?: string,
  primaryButtonDisabled?: boolean,
  primaryButtonNoLoader?: boolean,
  tertiaryType?: PlushButtonTypes | 'anchor',
  tertiaryButtonHidden?: boolean,
  tertiaryTag?: Tag,
  tertiaryLink?: string,
  tertiaryClick?: (() => void),
  tertiaryText?: string,
  tertiaryTextSize?: string,
  tertiaryButtonDisabled?: boolean,
  tertiaryButtonNoLoader?: boolean,
  height?: string,
  overflow?: string,
}

const ButtonPair = ({
  direction = 'vertical',
  order = 'normal',
  width = 'auto',
  gap = '20px',
  primaryType = 'primary',
  primaryTag = 'button',
  primaryLink,
  primaryClick,
  primaryText = '',
  primaryTextSize,
  primaryButtonDisabled = false,
  primaryButtonNoLoader = false,
  primaryButtonHidden = false,
  tertiaryType = 'primary-tertiary',
  tertiaryTag = 'button',
  tertiaryLink,
  tertiaryClick,
  tertiaryText = '',
  tertiaryTextSize,
  tertiaryButtonDisabled = false,
  tertiaryButtonNoLoader = false,
  tertiaryButtonHidden = false,
  height,
  overflow = 'hidden',
}: Props) => (
  <StyledButtonPair className="button-group" height={height} direction={direction} order={order} width={width} gap={gap}>
    {!primaryButtonHidden
      && (
        <ButtonPairItem
          type={primaryType}
          tag={primaryTag}
          link={primaryLink}
          onClick={primaryClick}
          text={primaryText}
          fontSize={primaryTextSize}
          isDisabled={primaryButtonDisabled}
          noLoader={primaryButtonNoLoader}
          height={height}
          overflow={overflow}
        />
      )}
    {!tertiaryButtonHidden
      && (
        <ButtonPairItem
          type={tertiaryType}
          tag={tertiaryTag}
          link={tertiaryLink}
          onClick={tertiaryClick}
          text={tertiaryText}
          fontSize={tertiaryTextSize}
          isDisabled={tertiaryButtonDisabled}
          noLoader={tertiaryButtonNoLoader}
          height={height}
          adsButtonType="rounded"
          overflow={overflow}
        />
      )}
  </StyledButtonPair>
);

export default ButtonPair;
