import React, { useState } from 'react';

import PlushCareLoader from '../PlushCareLoader';
import { StyledButton } from './Button.styled';

const Button = (props) => {
  const {
    children,
    tag,
    text,
    type,
    link,
    lineHeight,
    buttonSize,
    width,
    maxWidth,
    minWidth,
    height,
    maxHeight,
    minHeight,
    className = '',
    onClick,
    onKeyPress,
    noLoader,
    isLoading,
    isDisabled,
    style,
    fontSize,
    id,
    tabIndex = -1,
    ...rest
  } = props;
  const isMounted = React.useRef(true);
  const [loading, setLoading] = useState(false);

  React.useEffect(() => () => { isMounted.current = false; }, []);

  const handleOnClick = async (event) => {
    if (isDisabled) {
      event.preventDefault();
    }

    if (typeof onClick === 'undefined') {
      return;
    }
    if (!noLoader && isMounted) {
      setLoading(true);
    }
    if (onClick) {
      await Promise.resolve(onClick(event));
    }
    if (isMounted.current) {
      setLoading(false);
    }
  };

  return (
    <StyledButton
      as={tag}
      type={type}
      href={link}
      buttonSize={buttonSize}
      width={width}
      maxWidth={maxWidth}
      minWidth={minWidth}
      height={height}
      maxHeight={maxHeight}
      minHeight={minHeight}
      lineHeight={lineHeight}
      className={`${className}${isLoading || loading ? ' is-loading' : ''}${isDisabled ? ' is-disabled' : ''}`}
      onClick={handleOnClick}
      onKeyPress={onKeyPress}
      disabled={isLoading || loading || isDisabled}
      style={style}
      fontSize={fontSize}
      tabIndex={tabIndex}
      id={id}
      {...rest}
    >
      { (isLoading || loading)
        ? <PlushCareLoader className="plushcare-loader" />
        : (children || text)}
    </StyledButton>
  );
};

export default Button;
