import styled from '@emotion/styled';
import { colors } from '../helpers/plushcare-style-defaults';

export const Title = styled.h1`
  font-size: 24px;
  color: ${props => props.theme.colors.foreground};
  font-weight: 600;
  ${({ isAccoladeCare }) => isAccoladeCare && `
    color: ${colors.black};
  `};
`;
