import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Modal from 'src/js/components/Modal';
import TosWrapper from 'src/js/nextgen/components/TosWrapper';
import TosCheckbox from 'src/js/nextgen/components/TosCheckbox';
import ButtonPair from 'src/js/nextgen/plushcare-components/ButtonPair';
import { breakpoints, TermsOfService } from 'src/js/nextgen/plushcare-components';
import { useAppVariant } from 'utils/hooks/useAppVariant';
import { logout } from 'src/js/actions/UserActions';

type TermsOfServiceModalProps = {
  isOpen: boolean,
  onSubmit: () => void,
}

const TermsOfServiceModal = ({ isOpen, onSubmit }: TermsOfServiceModalProps) => {
  const [tosChecked, setTosChecked] = useState(false);
  const { displayName, isAccoladeX } = useAppVariant();
  const dispatch = useDispatch();

  const handleToggleClick = useCallback(
    () => {
      setTosChecked(prevState => !prevState);
    },
    [],
  );

  const handleCancel = useCallback(
    () => {
      if (isAccoladeX) {
        window.location.href = '/';
      } else {
        dispatch(logout());
      }
    },
    [
      dispatch,
      isAccoladeX,
    ],
  );

  return (
    <TosModal
      className="tos-modal"
      isOpen={isOpen}
      closeIcon={false}
      buttonGroup="none"
    >
      <h5>
        {displayName}
        {' '}
        terms and conditions
      </h5>
      <TosWrapper>
        <TosCheckbox id="tos-opt-in" label={<TermsOfService />} onChange={handleToggleClick} checked={tosChecked} />
      </TosWrapper>
      <ModalFooter>
        <ButtonPair
          direction="horizontal"
          primaryText="Continue"
          primaryClick={onSubmit}
          tertiaryText="Cancel"
          tertiaryClick={handleCancel}
          primaryButtonDisabled={!tosChecked}
        />
      </ModalFooter>
    </TosModal>
  );
};

export default TermsOfServiceModal;

const TosModal = styled(Modal)`
  h5 {
    text-align: left;
    margin-bottom: 16px;
  }
`;

const ModalFooter = styled.div`
  margin: 16px 0 25px 0;
  width: 50%;
  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
  }
`;
