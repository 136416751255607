// wrapper component for abstracting AB testing vendor
// see this Confluence space for all documentation related to AB testing
// https://accoladeinc.atlassian.net/wiki/spaces/ENGINEERIN/pages/1459224598/Optimizely
import {
  ReactNode, useEffect, useRef, useState,
} from 'react';
import {
  OptimizelyProvider, createInstance, setLogger, enums,
} from '@optimizely/react-sdk';
import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/nextjs';
import Analytics from 'src/js/utils/analytics/Analytics';
import * as env from 'utils/env';
import { onDecision } from 'src/js/abTesting/notificationListeners';
import { useIsEnterpriseUser, useUserData } from 'src/js/endpoints/users';
import { useAppVariant, AppVariants } from 'utils/hooks/useAppVariant';
import { isBrowser } from 'src/js/utils/server';
import DevLogger from 'src/js/logging/DevLogger';

/**
 * customErrorHandler
 *
 * Object that has a property `handleError` which will be called
 * when an error is thrown in the SDK.
 */

interface OptimizelyError extends Error {
  message: string;
  stack: string | undefined;
}

const errorHandler = {
  handleError(error: OptimizelyError) {
    datadogRum.addError(error);
    Sentry.captureException(error);
  },
};

// logger prints directly to browser console, so we want this disabled in prod
if (env.is('prod')) {
  setLogger(null);
}

type TestProviderProps = {
  children: ReactNode;
  datafile?: string;
  anonymousId?: string | null;
}

// userId is null on the client, but may be passed in on the server in _app.js::getInitialProps
const TestProvider = ({ children, datafile, anonymousId = null }: TestProviderProps) => {
  const sdkKey = process.env.NEXT_PUBLIC_OPTIMIZELY_KEY;
  const [userId, setUserId] = useState(anonymousId);
  const { data: { is_admin, is_logged_in, userprofile } } = useUserData();
  const { variant } = useAppVariant();
  const isEnterpriseUser = useIsEnterpriseUser();

  /**
   *  There's a big race condition with this component that we have to account for: the Optimizely SDK mounting
   *  before the Segment anonymous ID has been set.
   *
   *  We don't have a way of attaching a callback to the Segment SDK instantiating or the anonymous ID being set,
   *  so instead we have to poll the browser to check for the cookie.
   *  We can't bind event listeners to the various browser load events (eg. `DOMContentLoaded`, `load`,
   *  `readystatechange`) as these introduce more race conditions where if the component mounts after the event has
   *  fired, the event handler will never get called.
   *
   *  We limit this to 3 full seconds of attempts as otherwise this can lead to an infinite loop if the user is blocking
   *  Segment's cookie from getting set.
   *  We want this value to stick around between renders, so we're using useRef instead of useState.
   */
  const attempts = useRef(0);
  useEffect(() => {
    const segmentTimer = setInterval(() => {
      if (userId) {
        clearInterval(segmentTimer);
      }
      const segmentAnonId = Analytics.getSegmentAnonymousId();
      if (segmentAnonId) {
        setUserId(segmentAnonId);
        clearInterval(segmentTimer);
      }
      attempts.current += 1;
      /// 60 attempts * 50ms interval === 3 seconds
      if (attempts.current > 60) {
        DevLogger.log('[OPTIMIZELY]: Not initialized. Unable to get anonymous ID.');
        clearInterval(segmentTimer);
      }
    }, 50);

    return () => {
      clearInterval(segmentTimer);
    };
  }, []);

  if (!sdkKey) {
    DevLogger.log('NO OPTIMIZELY SDK KEY SET');
  }

  // if we initialized the sdk when userId is undefined it's stuck on that value. to fix, we only initialize
  // the sdk when we have the sdkKey + userId
  if ((!sdkKey && !datafile) || !userId) {
    return <>{children}</>;
  }
  // This instantiates the actual Optimizely client
  const optimizely = datafile
    ? createInstance({ datafile, errorHandler })
    : createInstance({ sdkKey, errorHandler });

  optimizely.notificationCenter.addNotificationListener(
    enums.NOTIFICATION_TYPES.DECISION,
    onDecision,
  );

  // this is how other notification listeners can be implemented if/when needed
  // the config notification listener works differently - we need to manually pass
  // the optimizely client to the callback function to actually have access to the data

  // optimizely.notificationCenter.addNotificationListener(
  //   enums.NOTIFICATION_TYPES.OPTIMIZELY_CONFIG_UPDATE,
  //   () => onConfigUpdateListener(optimizely),
  // );
  // optimizely.notificationCenter.addNotificationListener(
  //   enums.NOTIFICATION_TYPES.TRACK,
  //   onTrack,
  // );
  // optimizely.notificationCenter.addNotificationListener(
  //   enums.NOTIFICATION_TYPES.LOG_EVENT,
  //   onLogEvent,
  // );
  return (
    <OptimizelyProvider
      optimizely={optimizely}
      user={{
        id: userId,
        attributes: {
          /* is_admin is undefined if user isn't signed in, so this prevents us from accidentally passing undefined
          to Optimizely where a boolean is expected (also if it's undefined the key will just be absent from the object
          when serialized to JSON because JavaScript) */
          is_admin: Boolean(is_admin),
          is_logged_in: Boolean(is_logged_in),
          /* We have this additional check for enterprise users for the situation were an enterprise user
          is on the PlushCare app variant. */
          app_variant: isEnterpriseUser ? AppVariants.AccoladeCare : variant,
          coverage_type: userprofile?.coverage?.[0]?.coverage_type || null,
          is_repeat_user: Boolean(userprofile?.repeat_patient),
        },
      }}
      isServerSide={!isBrowser()}
    >
      {children}
    </OptimizelyProvider>
  );
};

export default TestProvider;
