import React from 'react';
import Lottie from 'lottie-react';
import styled from '@emotion/styled';

import * as animationData from 'public/json/lottie-loading.json';

const PlushCareLoader = (props) => {
  const { className } = props;

  return (
    <PlushCareLoaderStyled className={className} data-testid="loader">
      <Lottie
        animationData={animationData.default}
        loop
        autoplay
      />
    </PlushCareLoaderStyled>
  );
};

export default PlushCareLoader;

const PlushCareLoaderStyled = styled.span`
  display: block;
  width: 50px;
  height: 18px;
  margin: auto;
`;
