import * as React from 'react';
import { Portal } from '@accolade/design-system-react';
import styled from '@emotion/styled';

type ModalProps = {
  isOpen: boolean;
  children: React.ReactNode;
  className?: string;
  contentClassName?: string;
  id?: string;
  testId?: string;
  centered?: boolean;
}

export default function ADSModal({
  isOpen, children, className, contentClassName, id, testId, centered = false,
}: ModalProps) {
  const [openModal, setOpenModal] = React.useState(false);
  const [animationType, setAnimationType] = React.useState<'in' | 'out'>('in');

  React.useEffect(() => {
    if (isOpen) {
      setOpenModal(isOpen);
    }
  }, [isOpen]);

  React.useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (openModal && !isOpen) {
      setAnimationType('out');

      timeoutId = setTimeout(() => {
        setOpenModal(false);
        setAnimationType('in');
      }, 200);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isOpen, openModal]);
  return (
    <>
      {openModal && (
        <ModalPortal>
          <Overlay animationType={animationType} />
          <ModalContainer
            className={`${className} ${contentClassName}`}
            animationType={animationType}
            id={id}
            data-testid={testId}
            fixed
            centered={centered}
          >
            <Modal className="modal-content">
              {children}
            </Modal>
          </ModalContainer>
        </ModalPortal>
      )}
    </>
  );
}

type AnimationTypeProps = {
  animationType: 'in' | 'out';
}

const Overlay = styled.div<AnimationTypeProps>`
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: ${props => (props.animationType === 'in' ? 'fadeIn' : 'fadeOut')} 0.4s;
  opacity: 0.6;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 0.6;
    }
  }
  @keyframes fadeOut {
    from {
      opacity: 0.6;
    }
    to {
      opacity: 0;
    }
  }
`;

const ModalPortal = styled(Portal)`
  
`;

interface ModalContainerProps extends AnimationTypeProps {
  fixed: boolean;
  centered: boolean;
}

const ModalContainer = styled.div<ModalContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: ${props => (props.fixed ? 'fixed' : 'unset')} !important;
  width: 100%;
  ${props => `height: ${props.centered ? '100%' : 'fit-content'};`}
  ${props => (props.centered && 'align-items: center;')};

  animation: ${props => (props.animationType === 'in' ? 'drop' : 'lift')} 0.4s;
  @keyframes drop {
    from {
      opacity: 0;
      transform: translateY(-80px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes lift {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(-80px);
    }
  }
`;

const Modal = styled.div`
  min-height: auto !important;
  z-index: 99999;
`;

export const ModalBody = styled.div`
  padding: 16px;
`;

export const ModalHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 15px;
`;
