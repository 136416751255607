import { isServer } from '../../src/js/utils/server';

const ENVIRONMENTS = { PROD: 'prod' };
const ENV = process.env.NEXT_PUBLIC_ENV;
const SDK_ENDPOINT = 'sdk.iad-02.braze.com';
// In Braze The API key distinguishes between the different environments
const API_KEY = ENV === ENVIRONMENTS?.PROD ? '20b45a93-6f9b-47c2-8f32-837d92db249e' : '93375e1c-6284-4f85-8d4b-ea3fff4fba16';

const braze_configs = {
  baseUrl: SDK_ENDPOINT,
  sessionTimeoutInSeconds: 300,
  enableLogging: ENV !== ENVIRONMENTS.PROD,
};

export const initBraze = async (userId = null) => {
  if (isServer) {
    return Promise.reject(new Error('Braze SDK cannot be initialized on the server'));
  }

  const braze = await import('@braze/web-sdk');

  if (!braze.initialize(API_KEY, braze_configs)) {
    if (userId) {
      braze.changeUser(userId);
    }
    braze.openSession();
    braze.requestImmediateDataFlush();
  }
  return braze;
};
