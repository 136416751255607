import React, { useEffect, useState, useRef } from 'react';
import styled from '@emotion/styled';
import { connect, useDispatch, useSelector } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';
import { getLoginHealth } from 'src/js/endpoints/auth';
import Analytics from 'src/js/utils/analytics/Analytics';
import { logout } from 'src/js/actions/UserActions';

import Modal from 'src/js/components/Modal';
import ButtonPair from 'src/js/nextgen/plushcare-components/ButtonPair';

const AuthHealth = ({ isModalOpen = false, isLoggedIn = false }) => {
  const dispatch = useDispatch();

  const dashboard = useSelector((state: { dashboard: any; }) => state.dashboard);
  // Is true if certain interactive events fire
  // if this is true, we will request a session time extension
  const healthActivity = useRef<boolean>(false);
  // True if the 'logout now?' modal is showing
  // While this modal is open healthActivity cannot be true
  // forcing the user to logout if they do not click 'yes'
  const healthModal = useRef<boolean>(false);
  const sessionExpire = useRef<Dayjs>(dayjs('1812-01-01 00:00:00.000000+00:00'));
  const stayPressed = useRef<boolean>(false);
  const joinedAppointment = useRef<any>(null);

  const secondsBetweenDates = (x: Dayjs, y: Dayjs) => Math.abs(x.unix() - y.unix());
  const expired = () => sessionExpire.current <= dayjs();

  const [showModal, setShowModal] = useState<boolean>(isModalOpen);
  const [displaySeconds, setDisplaySeconds] = useState<number>(secondsBetweenDates(sessionExpire.current, dayjs()));

  const PING_SECONDS = 60;
  const SHOW_MODAL_WINDOW_MINUTES = 2;

  let healthTimer: any | null = null;
  let displayTimer: any | null = null;

  const checkLoginHealth = (reset = false) => {
    // TODO:: Migrate to V2 + TS
    getLoginHealth(reset)
      .then(async (data) => {
        if (data && data.data) {
          const session_expire = dayjs(data.data.session_expire);
          sessionExpire.current = session_expire;
          const now = dayjs();
          const is_session_close_to_expire = (
            now.add(SHOW_MODAL_WINDOW_MINUTES, 'minute') > session_expire && !expired()
          );
          if (is_session_close_to_expire) {
            setShowModal(true);
          }
          if (stayPressed.current) {
            stayPressed.current = false;
            setShowModal(false);
          }

          if (data.data.logout_client) {
            signOut();
          }
        }
      })
      .catch((err) => { Analytics.authException(err); });
  };

  const triggeredActivity = () => {
    if (!healthModal.current && document.hasFocus()) healthActivity.current = true;
  };

  const checkForActivity = () => {
    if (isLoggedIn) {
      if (showModal) {
        checkLoginHealth(false);
      } else if (joinedAppointment.current !== null) { // In a video call
        checkLoginHealth(true); // If we are currently in a video call, always reset the timer
      } else if (healthActivity.current) {
        checkLoginHealth(true);
      } else {
        checkLoginHealth(false);
      }
    }

    healthActivity.current = false;
  };

  const stopTimer = () => {
    clearInterval(healthTimer);
    clearInterval(displayTimer);
  };

  const startTimer = (seconds: number) => {
    stopTimer();
    healthTimer = setInterval(checkForActivity, seconds * 1000);
    displayTimer = setInterval(() => { setDisplaySeconds(secondsBetweenDates(sessionExpire.current, dayjs())); }, 1000);
  };

  const signOut = () => {
    if (isLoggedIn) {
      setShowModal(false);
      dispatch(logout());
    }
  };

  useEffect(() => {
    document.addEventListener('mousemove', triggeredActivity, true);
    document.addEventListener('keydown', triggeredActivity, true);
    document.addEventListener('scroll', triggeredActivity, true);
    document.addEventListener('touchstart', triggeredActivity, true);

    // Unmount
    return () => {
      document.removeEventListener('mousemove', () => { });
      document.removeEventListener('keydown', () => { });
      document.removeEventListener('scroll', () => { });
      document.removeEventListener('touchstart', () => { });

      stopTimer();

      healthActivity.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    joinedAppointment.current = dashboard.joinedAppointment;
  }, [dashboard]);

  useEffect(() => {
    if (isLoggedIn) {
      startTimer(PING_SECONDS); // Check for user activity every minute
      checkLoginHealth(false);
    } else {
      stopTimer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  // Visual patch to make sure the user gets visually logged out when the timer reaches zero
  useEffect(() => {
    if (displaySeconds < 1) signOut();
  }, [displaySeconds]);

  return (
    <>
      {
        isModalOpen || showModal ? (
          <Modal
            title="Automatic Sign Out for Your Protection"
            className="authhealth-modal"
            isOpen={showModal}
            closeIcon={false}
            buttonGroup="none"
          >
            <p>
              You have been inactive for a while. For your security,
              we will automatically sign you out in
              {` ${displaySeconds} `}
              seconds.
              Select “stay signed in” to continue your session.
            </p>
            <ModalFooter>
              <ButtonPair
                direction="horizontal"
                order="reverse"
                primaryClick={() => { stayPressed.current = true; checkLoginHealth(true); }}
                primaryText="Stay Signed In"
                primaryTextSize="14px"
                tertiaryClick={() => { signOut(); }}
                tertiaryText="Sign Out"
                tertiaryTextSize="14px"
                primaryButtonHidden={false}
                tertiaryButtonHidden={false}
                primaryButtonDisabled={stayPressed.current}
                tertiaryButtonDisabled={stayPressed.current}
              />
            </ModalFooter>
          </Modal>
        ) : null
      }
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    isLoggedIn: state.user?.isLoggedIn ?? false,
  };
};

const ModalFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  margin-top: 20px;
`;

export default connect(mapStateToProps)(AuthHealth);
