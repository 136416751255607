import { AppTheme } from 'src/js/nextgen/themes/base';
import { AccoladeCareTheme } from 'src/js/nextgen/themes/accoladecare';

export const BscTheme: AppTheme = {
  __name__: 'BscTheme',

  colors: {
    ...AccoladeCareTheme.colors,
    primary: '#0085CA',
    primaryHover: '#50B0E2',
    primaryBackground: '#50B0E2',
  },
};
