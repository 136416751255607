import React from 'react';

import StyledHelpLabel from './HelpLabel.styled';

const HelpLabel = ({ text }) => (
  <StyledHelpLabel>
    {text}
  </StyledHelpLabel>
);

export default HelpLabel;
