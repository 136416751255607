import { OptimizelyDecision, UserAttributes } from '@optimizely/optimizely-sdk';
import Analytics from 'src/js/utils/analytics/Analytics';
import * as env from 'utils/env';

type OptimizelyDecisionNotification = OptimizelyDecision & {
  type: string;
  attributes: UserAttributes;
  decisionInfo: Omit<OptimizelyDecision, 'userContext'> & { decisionEventDispatched: boolean };
  userId: string;
}
export const onDecision = (decision: OptimizelyDecisionNotification) => {
  if (!env.is('prod')) {
    // console.log('Optimizely onDecision listener invoked', decision);
  }
  const { userId, attributes, decisionInfo: { decisionEventDispatched, variationKey, flagKey } } = decision;
  if (decisionEventDispatched) {
    const anonId = Analytics.getSegmentAnonymousId();
    if (anonId !== userId) {
      Analytics.analyticsException('Web - Optimizely User ID Mismatch');
    }
    Analytics.track('Web - Optimizely Decision Notification', { variantID: variationKey, experimentID: flagKey, attributes });
  }
};
