import { useEffect } from 'react';
import { GetServerSidePropsContext } from 'next';
import { ParsedUrlQuery } from 'querystring';
import { colors } from 'src/js/nextgen/plushcare-components';
import { RequestWithToken } from '../serverTypes';

export type IncomingParams<Query extends ParsedUrlQuery = ParsedUrlQuery> =
  GetServerSidePropsContext<Query> & RequestWithToken

export function useCareerPromoInConsole() {
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_ENV !== 'local') {
      console.log(
        '%cHi there! We are looking for talented developers like you!\nhttps://plushcare.com/careers/',
        `font-weight: bold; font-size: 20px; line-height: 1.5; color: ${colors.white}; text-shadow: 1px 1px 0 ${colors.violet}, 2px 2px 0 ${colors.black}, 3px 3px 0 ${colors.midnight};background-image: linear-gradient(to top left, ${colors.midnight}, ${colors.midnight}, ${colors.violet})`,
      );
    }
  }, []);
}

export const toTitleCase = (str: string) => str.replace(
  /\w\S*/g,
  (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
);

// this differs from `toTitleCase` in that `toPascalCase` treats anything other than [a-zA-Z0-9] as separators, and
// preserves and capitilization already present in the string
// toTitleCase('some_string very-cool') -> 'Some_String Very-cool', toTitleCase('Some_UUID') -> 'Some_uuid'
// toPascalCase('some_string very-cool') -> 'SomeStringVeryCool', toPascalCase('Some_UUID') -> 'SomeUUID'
export function toPascalCase(str: string) {
  return str.split(/[\W_]/).map(segment => `${segment[0].toUpperCase()}${segment.slice(1)}`).join('');
}

// exists because Nextjs router does not provide nice and clean "asPath" in params passed to `getServerSideProps`
export function clearResolvedUrlFromQuery(url: string) {
  const splitUrl = url.split('/?');
  return splitUrl.length === 1 ? url : `${splitUrl[0]}/`;
}
