import styled from '@emotion/styled';
import { colors } from '../plushcare-components';

const TosWrapper = styled.div`
  label {
    display: flex;
    flex-direction: row;
  }
  
  div {
    margin-top: 0;
  }
  
  #tos-opt-in:focus + label:before {
    outline: 2px solid ${colors.darkerBlue};
  }

  .tos-text {
    margin-bottom: 8px;
    text-align: left;
    margin-left: 8px;
    line-height: 20px;

    a {
      color: ${colors.darkerBlue};
    }
  }
`;

export default TosWrapper;
