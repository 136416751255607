export const POST_ANSWER = 'POST_ANSWER';
export const APPEND_ANSWER = 'APPEND_ANSWER';

export const GET_PROGRAM_SUCCESS = 'GET_PROGRAM_SUCCESS';
export const SET_CHECKLIST = 'SET_CHECKLIST';
export const GET_ACTIVE_PROGRAMS_SUCCESS = 'GET_ACTIVE_PROGRAMS_SUCCESS';
export const GET_APPOINTMENT_SUCCESS = 'GET_APPOINTMENT_SUCCESS';
export const GET_CHECKLIST_SUCCESS = 'GET_CHECKLIST_SUCCESS';
export const UPDATE_COMPLETED_CHECKLIST_ITEM = 'UPDATE_COMPLETED_CHECKLIST_ITEM';

// user based action types
export const FETCH_USER = 'FETCH_USER';
export const RECEIVE_USER = 'RECEIVE_USER';
export const GET_PROGRAMS_SUCESS = 'GET_PROGRAMS_SUCESS';

// Dashboard SPA
export const GET_DASHBOARD_DATA_SUCCESS = 'GET_DASHBOARD_DATA_SUCCESS';
export const GET_PRESCRIPTIONS_DATA_SUCCESS = 'GET_PRESCRIPTIONS_DATA_SUCCESS';
export const GET_TASKS_DATA_SUCCESS = 'GET_TASKS_DATA_SUCCESS';
export const COMPLETE_ITEM_FROM_TASKS_MODAL = 'COMPLETE_ITEM_FROM_TASKS_MODAL';

export const SET_ACTIVE_PROGRAMS = 'SET_ACTIVE_PROGRAMS';

export const GET_VISIT_HISTORY_DATA_SUCCESS = 'GET_VISIT_HISTORY_DATA_SUCCESS';
export const GET_TESTS_AND_RESULTS_DATA_SUCCESS = 'GET_TESTS_AND_RESULTS_DATA_SUCCESS';
export const GET_YOUR_HEALTH_DATA_SUCCESS = 'GET_YOUR_HEALTH_DATA_SUCCESS';
export const CLEAR_SOCIAL_ERROR = 'CLEAR_SOCIAL_ERROR';

// AOV logic for preappointment
export const SET_AOV_ONLY = 'SET_AOV_ONLY';
export const SET_COUNSELING_ITEMS = 'SET_COUNSELING_ITEMS';

export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';
export const GET_USER_DATA_ERROR = 'GET_USER_DATA_ERROR';

// Join Appointment
export const JOIN_APPOINTMENT = 'JOIN_APPOINTMENT';
export const CLEAR_JOIN_APPOINTMENT = 'CLEAR_JOIN_APPOINTMENT';

export const UPDATE_SUBSCRIPTIONS = 'UPDATE_SUBSCRIPTIONS';
