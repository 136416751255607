import { AvailableDoctor } from 'src/js/endpoints/appointments';

// Data

export type FilterItem = {
  selected: boolean,
  disabled: boolean,
  hidden: boolean,
  value: string | number | boolean | [],
  notSelectedValue: string | number | boolean | [],
}

// Add a filter group to a useState and auto-update all filters magic
// myFilterGroup['idOfFilter'].value = callbackValue
// label = myFilterGroup['idOfFilter'].value
export type FilterGroup = { [name: string]: FilterItem };

// ... Data

// Pill

export type PillState = {
    label: string,
    selected: boolean,
    disabled: boolean,
    hidden: boolean,
    count?: number
}

export type FilterPillCallback =
  {
    (id: string, selected:boolean, value: any): any,
  };

export type FilterPillState = PillState &
  {
    id: string,
    callback: FilterPillCallback,
  };

// ... Pill

// Modal

export type FilterModalSelectCallback =
  {
    (value: any): any,
  }

export type FilterModalCloseCallback =
  {
    (): any,
  }

export type ModalChild =
  {
    selectValue?: FilterModalSelectCallback | undefined | null, // Will be injected when the modal boots up
    initialValue: any, // Only value required for this type of component
    notSelectedValue?: any, // Will be injected when the modal boots up
    values?: any, // Will be injected when the modal boots up
  };

// ... Modal

// Final Filter

export type FilterCallBack =
  {
    (filterGroup: FilterGroup): any,
  };

export type FilterProps =
  {
    filterCallback: FilterCallBack,
    disabled: boolean,
    specialist: boolean,
    state: string,
  };

export type UrlFilterData =
  {
    specialty: string | null,
    doctor_type: string | null,
    page_size: number | null,
    page: number | null,
    payer_id: string | null,
    state: string | null,
    language: string | null,
    gender: string | null,
    lgbtq: boolean | null,
    patient_age_category: string | null,
  }

export const defaultUrlFilterData: UrlFilterData = {
  doctor_type: null,
  page_size: null,
  page: null,
  payer_id: null,
  state: null,
  language: null,
  gender: null,
  lgbtq: null,
  specialty: null,
  patient_age_category: null,
};

// ... Final Filter

// Clinician

export type ToggleClinicianModal =
  {
    (clinician: AvailableDoctor | null): any,
  }

export type DoctorListProps = {
    call: () => FilterPayload;
    Filters: FilterGroup,
    buildParams: (filterGroup: FilterGroup, insurance?: any, page: number, pageSize: number) => any,
    data: any
  }

export type FilterParams =
  {
    doctor_type?: string | null,
    page?: number | null,
    page_size?: number | null,
  }

export type FilterPayload =
  {
    doctors: AvailableDoctor[],
    params?: FilterParams | null,
    count?: number | null,
    next?: number | null,
    previous?: number | null,
  } | null

export type pagination = {
    currentPage: number;
    nextPage: number;
}
export type GetParams = { [name: string]: any };

// ... Clinician
