import { useOneTrustContext } from '@accolade/care-analytics/react';
import * as env from 'utils/env';
import { ENVIRONMENTS } from 'utils/env';

export default function CriteoInit() {
  const { targetingCookiesAllowed } = useOneTrustContext();
  const src = env.is(ENVIRONMENTS.STAGE)
    ? '//dynamic.criteo.com/js/ld/ld.js?a=78722'
    : '//fake-criteo-script.com/js/ld/ld.js';

  return !targetingCookiesAllowed ? null : (
    // @ts-expect-error
    <script id="CriteoScript" data-testid="CriteoScript" type="text/javascript" async="true" src={src} />
  );
}
