import React from 'react';
import { TotalProgress, PartialProgress } from './NavProgressBar.styled';

const NavProgressBar = ({ total, progress, fraction = undefined }) => {
  let percentage;
  if (fraction) {
    percentage = `${fraction * 100}%`;
  } else {
    percentage = (total && progress) ? `${Math.min(100, 100 * (progress / total))}%` : '2%';
  }

  return (
    <div>
      <PartialProgress percentage={percentage} />
      <TotalProgress />
    </div>
  );
};

export default NavProgressBar;
