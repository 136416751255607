/* eslint-disable no-console */
import * as env from 'utils/env';

// This is an environment-safe console.log wrapper
export default class DevLogger {
  private static print(logFn: () => void) {
    if (!env.is(env.ENVIRONMENTS.PROD)) {
      logFn();
    }
  }

  static error(...args: Parameters<typeof console.error>): void {
    DevLogger.print(() => console.error(...args));
  }

  static warn(...args: Parameters<typeof console.warn>) {
    DevLogger.print(() => console.warn(...args));
  }

  static log(...args: Parameters<typeof console.log>) {
    DevLogger.print(() => console.log(...args));
  }

  static info(...args: Parameters<typeof console.info>) {
    DevLogger.print(() => console.info(...args));
  }

  static dir(...args: Parameters<typeof console.dir>) {
    DevLogger.print(() => console.dir(...args));
  }

  static dirxml(...args: Parameters<typeof console.dirxml>) {
    DevLogger.print(() => console.dirxml(...args));
  }

  static time(...args: Parameters<typeof console.time>) {
    DevLogger.print(() => console.time(...args));
  }

  static clear() {
    DevLogger.print(() => console.clear());
  }

  static count(...args: Parameters<typeof console.count>) {
    DevLogger.print(() => console.count(...args));
  }

  static group(...args: Parameters<typeof console.group>) {
    DevLogger.print(() => console.group(...args));
  }

  static debug(...args: Parameters<typeof console.debug>) {
    DevLogger.print(() => console.debug(...args));
  }

  static table(...args: Parameters<typeof console.table>) {
    DevLogger.print(() => console.table(...args));
  }

  static trace(...args: Parameters<typeof console.trace>) {
    DevLogger.print(() => console.trace(...args));
  }

  static assert(...args: Parameters<typeof console.assert>) {
    DevLogger.print(() => console.assert(...args));
  }

  static timeEnd(...args: Parameters<typeof console.timeEnd>) {
    DevLogger.print(() => console.timeEnd(...args));
  }

  static groupEnd(...args: Parameters<typeof console.groupEnd>) {
    DevLogger.print(() => console.groupEnd(...args));
  }

  static groupCollapsed(...args: Parameters<typeof console.groupCollapsed>) {
    DevLogger.print(() => console.groupCollapsed(...args));
  }

  static timeLog(...args: Parameters<typeof console.timeLog>) {
    DevLogger.print(() => console.timeLog(...args));
  }

  static countReset(...args: Parameters<typeof console.countReset>) {
    DevLogger.print(() => console.countReset(...args));
  }
}
