import { sha3_256 } from 'js-sha3';
import { useRouter } from 'next/router';
import { PriceSymbolType } from 'src/js/endpoints/therapy';
import { LocalStorage } from 'src/js/utils';
import Analytics from 'src/js/utils/analytics/Analytics';

export const LOCAL_STORAGE_THERAPY_NAME = 'therapy_flow';

export type TherapyFlowType = {
  therapist: string;
  recurring: boolean;
  appointment: string;
  reschedule: boolean;
  rescheduleAppt: string;
  symbol: PriceSymbolType;
};

type TherapyFlowHandlerActionsType = 'add' | 'clear' | 'get';
type TherapyFlowHandlerValuesType = Partial<TherapyFlowType> & {
  child?: string;
  newChild?: boolean;
};

export const THERAPY_CONSENT_TYPE = {
  ADULT: 'therapy-adult-consent',
  PARENT: 'therapy-parental-consent',
  MINOR: 'therapy-minor-consent',
};

export type TreeckoFormState = {
  name: typeof THERAPY_CONSENT_TYPE[keyof typeof THERAPY_CONSENT_TYPE];
  opted_in: boolean;
  consenter: string;
  form: string;
  content: string;
  label: string;
  isHidden: boolean;
}[];

export const objLookup = <T, K extends keyof T>(obj: T & {default: any}, opt: K, def = null) => {
  return obj[opt] || obj.default || def;
};

export const therapyFlowHandler = (action: TherapyFlowHandlerActionsType, values?: TherapyFlowHandlerValuesType) => {
  if (action === 'clear') return LocalStorage.removeItem(LOCAL_STORAGE_THERAPY_NAME);

  const therapyFlow = LocalStorage.getItem(LOCAL_STORAGE_THERAPY_NAME);

  if (action === 'add' && values) {
    return LocalStorage.setItem(LOCAL_STORAGE_THERAPY_NAME, {
      ...therapyFlow,
      ...values,
    });
  }

  return therapyFlow;
};

export const useIsTherapyFlow = () => {
  const router = useRouter();
  return router.pathname.includes('booking/therapy/');
};

export const isWeeklyOrBiweekly = (frequency: PriceSymbolType | null) => frequency?.includes('WEEKLY');

export const saveChildUUIDRecord = async (userId: string, childId: string, childUUID?: string) => {
  Analytics.track('Adolescent Therapy Consent Form - Child Info', {
    parent: userId,
    child: childId,
    new_child_uuid: childUUID,
  });
};

export const generateDeterministicUuid = (child_id: number, user_uuid: string) => {
  const combinedIds = `child:${child_id}-user:${user_uuid}`;
  const hash = sha3_256(combinedIds);
  return formatAsUuid(hash);
};

export const formatAsUuid = (hash: string) => {
  return `${hash.slice(0, 8)}-${hash.slice(8, 12)}-${hash.slice(12, 16)}-${hash.slice(16, 20)}-${hash.slice(20, 32)}`;
};
