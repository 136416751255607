import { useMemo } from 'react';
import { appUrl } from 'src/js/utils';
import { IS_ACCOLADE_X, AppVariants, getAppVariant } from 'utils/hooks/useAppVariant';

const useAppUrl = (isAccoladeX: boolean = IS_ACCOLADE_X) => {
  return useMemo(
    () => {
      return {
        dashboardUrl: isAccoladeX ? appUrl.accoladeX.dashboard : appUrl.dashboard,
      };
    },
    [isAccoladeX],
  );
};

export default useAppUrl;

export const getAppVariantDomain = () => {
  const [variant, isAccoladeX] = getAppVariant();
  return isAccoladeX
    ? '.accolade.com'
    : variant === AppVariants.AccoladeCare
      ? '.accoladecare.com'
      : '.plushcare.com';
};
