import { theme } from '@accolade/design-system-react';

import { AppTheme } from 'src/js/nextgen/themes/base';

export const AccoladeCareTheme: AppTheme = {
  __name__: 'AccoladeCareTheme',

  colors: {
    background: theme.colors.background,
    foreground: theme.colors.foreground,
    gray1: theme.colors.gray1,
    gray2: theme.colors.gray2,
    gray3: theme.colors.gray3,
    gray4: theme.colors.gray4,
    gray5: theme.colors.gray5,
    gray6: theme.colors.gray6,
    gray7: theme.colors.gray7,
    gray8: theme.colors.gray8,
    primary: theme.colors.primary.default,
    primaryHover: theme.colors.primary.hover,
    primaryBackground: theme.colors.primary.background,
    warning: theme.colors.warning.default,
  },
};
