import {
  SET_APPOINTMENT_DATA, SET_CLEAR_PHARMACY_STORE, SET_ERROR, SET_HOME_ADDRESS, SET_LIST_LOADING, SET_LOADING,
  SET_PHARMACY_ADDRESS, SET_PHARMACY_LIST, SET_POST_SUCCESS, SET_SPECIALTY,
} from '../actionTypes/BookingActionType';
import { Appointment } from '../globalTypes';

export interface PharmacyState {
  homeAddress: any,
  appointmentData: Appointment,
  pharmacyAddress: PharmacyAddress,
  specialty: any,
  isLoading: boolean,
  isExisting: boolean,
  error: any,
  postSuccessful: boolean,
  isListLoading: boolean,
  isPharmacySelected: boolean,
  pharmacyList: any[],
}

export interface PharmacyAddress {
  specialty_type: string // 'Retail'
  city: string // 'San Francisco'
  organization_name: string // 'Tin Rx Castro - San Francisco CA'
  state: string // 'CA'
  version: string // 'v6_1_erx'
  latitude: string // '37.766291'
  email: string
  zip_code: string // '94114'
  phone_number: string // '6288959508' // maybe not used
  ncpdp_id: string // '5669962'
  address_line_one: string // '2175 Market St.'
  longitude: string // '-122.429927'
  address_line_two: string
  npi: string
}

const initalState = {
  homeAddress: null,
  pharmacyAddress: null,
  appointmentData: null,
  specialty: null,
  isLoading: false,
  isExisting: false,
  error: null,
  postSuccessful: null,
  isListLoading: false,
  isPharmacySelected: false,
  pharmacyList: null,
};

function PharmacyReducer(state = initalState, action: Action) {
  switch (action.type) {
    case SET_HOME_ADDRESS:
      return {
        ...state,
        homeAddress: action.homeAddress,
      };
    case SET_PHARMACY_ADDRESS:
      return {
        ...state,
        pharmacyAddress: action.pharmacyAddress,
        isPharmacySelected: !!action.pharmacyAddress,
        isExisting: action.isExisting,
      };
    case SET_APPOINTMENT_DATA:
      return {
        ...state,
        appointmentData: action.appointmentData,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case SET_LIST_LOADING:
      return {
        ...state,
        isListLoading: action.isListLoading,
      };
    case SET_PHARMACY_LIST:
      return {
        ...state,
        pharmacyList: action.pharmacyList,
        isPharmacySelected: false,
      };
    case SET_SPECIALTY:
      return {
        ...state,
        specialty: action.specialty,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case SET_POST_SUCCESS:
      return {
        ...state,
        postSuccessful: action.isSuccessful,
        isPharmacySelected: false,
      };
    case SET_CLEAR_PHARMACY_STORE:
      return {
        ...state,
        error: null,
        isLoading: null,
        postSuccessful: null,
        isPharmacySelected: !!action.pharmacyAddress,
      };
    default:
      return state;
  }
}

export default PharmacyReducer;

type Action = {
  type: typeof SET_HOME_ADDRESS
  homeAddress: PharmacyState['homeAddress']
} | {
  type: typeof SET_PHARMACY_ADDRESS
  pharmacyAddress: PharmacyState['pharmacyAddress']
  isPharmacySelected: PharmacyState['isPharmacySelected']
  isExisting: PharmacyState['isExisting']
} | {
  type: typeof SET_APPOINTMENT_DATA
  appointmentData: PharmacyState['appointmentData']
} | {
  type: typeof SET_LOADING
  isLoading: PharmacyState['isLoading']
} | {
  type: typeof SET_LIST_LOADING
  isListLoading: PharmacyState['isListLoading']
} | {
  type: typeof SET_PHARMACY_LIST
  pharmacyList: PharmacyState['pharmacyList']
} | {
  type: typeof SET_SPECIALTY
  specialty: PharmacyState['specialty']
} | {
  type: typeof SET_ERROR
  error: PharmacyState['error']
} | {
  type: typeof SET_POST_SUCCESS
  isSuccessful: PharmacyState['postSuccessful']
} | {
  type: typeof SET_CLEAR_PHARMACY_STORE
  pharmacyAddress: PharmacyState['pharmacyAddress']
}
