export const SET_FIRSTNAME = 'SET_FIRSTNAME';
export const SET_LASTNAME = 'SET_LASTNAME';
export const SET_GENDER = 'SET_GENDER';
export const SET_WEIGHT = 'SET_WEIGHT';
export const SET_MONTH = 'SET_MONTH';
export const SET_DAY = 'SET_DAY';
export const SET_YEAR = 'SET_YEAR';
export const SET_SHOW_CHILD_FORM = 'SET_SHOW_CHILD_FORM';

export const SET_FIRSTNAME_VALID = 'SET_FIRSTNAME_VALID';
export const SET_LASTNAME_VALID = 'SET_LASTNAME_VALID';
export const SET_GENDER_ERROR = 'SET_GENDER_ERROR';
export const SET_WEIGHT_VALID = 'SET_WEIGHT_VALID';
export const SET_FORM_VALID = 'SET_FORM_VALID';
export const SET_BIRTHDATE_ERROR = 'SET_BIRTHDATE_ERROR';

export const SET_FIRSTNAMETOOLTIP_OPEN = 'SET_FIRSTNAMETOOLTIP_OPEN';
export const SET_LASTNAMETOOLTIP_OPEN = 'SET_LASTNAMETOOLTIP_OPEN';

export const SET_SUBMITDISABLED = 'SET_SUBMITDISABLED';
export const SET_SUBMITTINGCHILDFORM = 'SET_SUBMITTINGCHILDFORM';
export const TOGGLE_SPINNER = 'TOGGLE_SPINNER';

export const SET_CHILD_ERROR = 'SET_CHILDERROR';
