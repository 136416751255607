import styled from '@emotion/styled';
import { colors } from '../helpers/plushcare-style-defaults';

export const TotalProgress = styled.div`
  position: relative;
  top: -4px;
  height: 4px;
  width: 100%;
  background: ${colors.rain};
`;

export const PartialProgress = styled.div`
  position: relative;
  top: 0;
  height: 4px;
  width: ${props => props.percentage};
  background: ${props => props.theme.colors.primary};
  z-index: 1;
`;
