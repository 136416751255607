import { getCurrentDay, getCurrentMonth, getCurrentYear } from 'src/js/utils/datetime';
import {
  SET_FIRSTNAME, SET_LASTNAME, SET_GENDER, SET_WEIGHT, SET_MONTH,
  SET_DAY, SET_YEAR, SET_FIRSTNAME_VALID, SET_LASTNAME_VALID, SET_GENDER_ERROR,
  SET_WEIGHT_VALID, SET_FORM_VALID, SET_BIRTHDATE_ERROR, SET_FIRSTNAMETOOLTIP_OPEN,
  SET_LASTNAMETOOLTIP_OPEN, SET_SUBMITDISABLED, SET_SHOW_CHILD_FORM, SET_CHILD_ERROR,
  TOGGLE_SPINNER,
} from 'src/js/components/Child/ChildActionTypes';

const initialState = {
  childFirstName: '',
  childLastName: '',
  childGender: '',
  childWeight: '',
  childMonth: getCurrentMonth(),
  childDay: getCurrentDay(),
  childYear: getCurrentYear(),
  childFirstNameValid: true,
  childLastNameValid: true,
  childGenderError: '',
  childWeightValid: true,
  childFormValid: false,
  childBirthDateError: '',
  childFirstNameTooltipOpen: false,
  childLastNameTooltipOpen: false,
  childSubmitDisabled: false,
  childError: null,
  childDisplaySpinner: false,
};

function ChildReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_FIRSTNAME:
      return { ...state, childFirstName: action.value };
    case SET_FIRSTNAME_VALID:
      return { ...state, childFirstNameValid: action.value };
    case SET_LASTNAME:
      return { ...state, childLastName: action.value };
    case SET_LASTNAME_VALID:
      return { ...state, childLastNameValid: action.value };
    case SET_WEIGHT:
      return { ...state, childWeight: action.value };
    case SET_WEIGHT_VALID:
      return { ...state, childWeightValid: action.value };
    case SET_GENDER:
      return { ...state, childGender: action.value };
    case SET_GENDER_ERROR:
      return { ...state, childGenderError: action.value };
    case SET_MONTH:
      return {
        ...state,
        childMonth: action.value,
        childMonthChanged: true,
      };
    case SET_DAY:
      return {
        ...state,
        childDay: action.value,
        childDayChanged: true,
      };
    case SET_YEAR:
      return {
        ...state,
        childYear: action.value,
        childYearChanged: true,
      };
    case SET_BIRTHDATE_ERROR:
      return { ...state, childBirthDateError: action.value };
    case SET_FIRSTNAMETOOLTIP_OPEN:
      return { ...state, childFirstNameTooltipOpen: action.value };
    case SET_LASTNAMETOOLTIP_OPEN:
      return { ...state, childLastNameTooltipOpen: action.value };
    case SET_SUBMITDISABLED:
      return { ...state, childSubmitDisabled: action.value };
    case SET_FORM_VALID:
      return { ...state, childFormValid: action.value };
    case SET_SHOW_CHILD_FORM:
      return { ...state, showChildForm: action.value };
    case SET_CHILD_ERROR:
      return { ...state, setChildError: action.value };
    case TOGGLE_SPINNER:
      return { ...state, childDisplaySpinner: action.value };
    default:
      return state;
  }
}

export default ChildReducer;
